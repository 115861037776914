.sidebar-list {
  position: fixed;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  width: 340px;
  top: 0;
  height: 100%;
  background-color: #2d2f31;
  box-shadow: -3px 0px 10px 0 rgba(0, 0, 0, 0.3);
  transition: right var(--sidebar-content-transition-time);
  z-index: 1;
}

.sidebar-list--normal-view {
  right: -260px;
}

.sidebar-list--fullscreen {
  right: -340px;
}

.sidebar-list--normal-view-shown {
  right: 80px;
}

.sidebar-list--fullscreen-shown {
  right: 0px;
}

.sidebar-list__main {
  flex: 1;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
}

.sidebar-list__main::-webkit-scrollbar {
  display: none;
}

.sidebar-list__main--padded {
  padding: 10px 20px;
}

.sidebar-list__footer {
  height: auto;
  min-height: 70px;
  background-color: #222427;
}

.sidebar-list__footer--max-height-big {
  max-height: 140px;
}

.sidebar-list__footer--max-height-small {
  max-height: 70px;
}
