.c-pw-input {
  height: 44px;
  width: 100%;
  box-sizing: border-box;
  border-top: 1px solid #c2c4c7;
  border-bottom: 1px solid #c2c4c7;
  border-left: 1px solid #c2c4c7;
  border-right: none;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  font-size: 16px;
  display: inline-block;
  padding: 10px;
  outline: none;
  -moz-appearance: textfield;
}

.c-pw-input::-webkit-inner-spin-button,
.c-pw-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.c-pw-input::-ms-reveal,
.c-pw-input::-ms-clear {
  display: none;
}

.c-pw-vis {
  height: 44px;
  width: 44px;
  box-sizing: border-box;
  border-top: 1px solid #c2c4c7;
  border-bottom: 1px solid #c2c4c7;
  border-right: 1px solid #c2c4c7;
  border-left: none;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: #ffffff;
  outline: none;
}

.c-pw-vis__icon {
  height: 20px;
  width: 20px;
  cursor: pointer;
  outline: none;
}

.c-pw-vis__icon--show {
  background-image: url("../../images/icon-show-password.svg");
}

.c-pw-vis__icon--hide {
  background-image: url("../../images/icon-hide-password.svg");
}
