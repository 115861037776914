.sidebar {
  display: none;
}

.fullscreen-controls {
  width: 80px;
  margin: 0;
  top: 20px;
  display: block;
  position: fixed;
  right: 0;
  height: 100%;
}
.fullscreen-controls{
  min-width: 80px;
  min-height: 100%;
}

.fullscreen-controls ul {
  height: 100%;
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: absolute;
}

.fullscreen-controls ul li,
.fullscreen-controls.mouse-inactive:hover ul li {
  width: 60px;
  background-color: #323437;
  opacity: 0.75;
  transition: opacity 0.3s;
}

.fullscreen-controls.mouse-inactive ul li {
  opacity: 0;
}

.fullscreen-controls.mouse-inactive ul li:hover,
.fullscreen-controls ul li:hover {
  opacity: 1;
}

.fullscreen-controls li.sidebar-item {
  position: relative;
  cursor: pointer;
  padding: 13px 0;
  border-radius: 50px;
  margin-top: 20px;
}

.fullscreen.mouse-inactive {
  cursor: none;
}

.fullscreen-controls li > span {
  margin: 0 auto;
  width: 34px;
  height: 34px;
  background-repeat: no-repeat;
  display: block;
}

.fullscreen-controls li span.action-participantlist {
  background: url(../images/icon-participant-list.svg);
}

.fullscreen-controls li span.action-invite {
  background: url(../images/icon-add-participant.svg);
}

.fullscreen-controls li span.action-audio {
  background: url(../images/icon-phone.svg);
}

.fullscreen-controls li span.action-chat {
  background: url(../images/icon-chat.svg);
}

.fullscreen-controls li span.action-settings {
  background: url(../images/icon-settings.svg);
}

.fullscreen-controls li span.action-quit {
  background: url(../images/icon-logout.svg);
}

.fullscreen-controls li span.action-usermenu .dropbtn {
  background: url(../images/icon-dots.svg);
  height: 34px;
  width: 34px;
}

.fullscreen-controls li span.action-exit-fullscreen {
  background: url(../images/icon-window-mode.svg);
  height: 34px;
  width: 34px;
}

.fullscreen-controls li.exitfullscreen {
  bottom: 200px;
  position: absolute;
}

.fullscreen-controls li.settings {
  bottom: 120px;
  position: absolute;
}

.fullscreen-controls li.quit {
  bottom: 40px;
  position: absolute;
}

.fullscreen-controls li.participantlistfullscreen {
  /* margin-top: 40px; */
}

.participantlist {
  right: -340px;
}

.participantlist.shown {
  right: 0px !important;
}

.tooltip .tooltiptext {
  right: 75px;
}

.sidebar__top-button--fullscreen-margin {
  margin-top: 40px;
}

.sidebar__middle-button--fullscreen-margin {
  margin-top: 20px;
}
