.c-btn {
  border-radius: 4px;
  text-align: center;
  padding: 10px;
  display: inline-block;
  cursor: pointer;
  box-shadow: none;
  transition: box-shadow 0.2s;
  border: 0px;
  user-select: none;
}

.c-btn:hover {
  box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.2);
}

.c-btn:focus,
.c-btn:active {
  box-shadow: inset 0 0 100px 100px rgba(0, 0, 0, 0.1);
}

.c-btn--long {
  width: 180px;
}

.c-btn--small {
  width: 70px;
}

.c-btn--max {
  width: calc(100% - 20px);
}

.c-btn--grey {
  background-color: #9ba2ac;
  color: #ffffff;
}

.c-btn--branded {
  background-color: var(--actionbutton_background-color);
  color: var(--actionbutton_hover_background-color);
}
