.dropdown {
  position: relative;
  display: inline-block;
  text-align: left;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  /* position: relative;
  right: 200px; */
  background-color: #323437;
  border-radius: 4px;
  min-width: 225px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.dropdown-content-up {
  position: absolute;
  right: 0px;
  bottom: var(--bottom-spacing);
}

.dropdown-content-down {
  position: absolute;
  right: 0px;
  top: 40px;
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content span {
  color: #c2c4c7;
  padding: 16px;
  text-decoration: none;
  display: block;
}

/* Fix for embedded a tags */
.dropdown-content span a {
  display: block;
  margin: -16px;
  padding: 16px;
}

/* Change color of dropdown links on hover */
.dropdown-content span:hover {
  background-color: #404245;
  color: #ffffff;
  cursor: pointer;
}

.dropdown-content span:first-of-type {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.dropdown-content span:last-of-type {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.dropdown-button {
  position: relative;
  background: url(../images/icon-dots.svg);
  /* background: no-repeat; */
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
  height: 100%;
}

.dropdown-button--no-symbol {
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
  height: 100%;
}

.dropdown-content-entry {
  position: relative;
  /* display: block;
  color: #c2c4c7;
  padding: 16px;
  text-decoration: none; */
}

.dropdown-content-entry--link-fix {
  display: block;
  margin: -16px;
  padding: 16px;
}

.dropdown-content-entry:hover {
  background-color: #404245;
  color: #ffffff;
  cursor: pointer;
}

.dropdown-content-entry:first-of-type {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.dropdown-content-entry:last-of-type {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.dropdown-content-entry__grey-out {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(226, 226, 226, 0.7);
}

.dropdown-content-entry__text {
  display: block;
  color: #c2c4c7;
  padding: 16px;
  text-decoration: none;
}

.dropdown-content-entry__grey-out-image {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 12px 112px 12px 112px;
  height: 28px;
  width: 28px;
  -webkit-mask: url(../images/icon-lock.svg);
  background-color: #404245;
}
