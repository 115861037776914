.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  color: #222427;
  display: flex;
  align-items: center;
  z-index: 1;
}

.modal-box {
  margin: 0 auto;
  padding: 0;
  background-color: #f2f2f2;
  border-radius: 4px;
  overflow: auto;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.5);
  position: relative;
}

.modal-box--big {
  min-width: 510px;
  max-width: 520px;
}

.modal-box--small {
  min-width: 315px;
  max-width: 325px;
}

.modal-top {
  background-color: #e2e4e7;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 24px;
  text-align: left;
}

.modal-bottom {
  display: block;
}

.modal-bottom--big {
  padding: 34px 24px 50px;
}

.modal-bottom--small {
  padding: 25px 24px 25px;
}

.icon-close {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  background-image: url(../images/icon-close-lightbox.svg);
  display: block;
  height: 14px;
  width: 14px;
  opacity: 0.5;
  transition: opacity 0.2s;
}

.icon-close:hover {
  opacity: 1;
}

.inputblock a {
  color: blue;
}

/* ================== */
/* = FEEDBACK START = */
/* ================== */

.feedbackblock {
  display: flex;
  /* width: 180px; */
  margin: 14px 0 24px 0;
}

.feedbackblock a {
  display: block;
  height: 30px;
  width: 30px;
  fill: #c2c4c7;
  flex: 1;
  transition: fill 0.2s;
}

.feedbackblock a:hover,
.feedbackblock a:active,
.feedbackblock a:focus {
  fill: #fed30b;
}

/* label.callback {
  display: inline-block;
  margin-left: 10px;
} */

/* ================= */
/* = FEEDBACK ENDE = */
/* ================= */

.callbackblock {
  display: block;
}

#callback {
  display: inline-block;
}

.btn-wrapper {
  display: flex;
}

.btn-wrapper--margin-top {
  margin-top: 30px;
}

.btn-wrapper--margin-top-small {
  margin-top: 16px;
}

.btn {
  flex: 1;
}

.btn-grey {
  margin-right: 1%;
}

.btn-cta {
  margin-left: 1%;
}

.btn-cta_link {
  color: var(--actionbutton_hover_background-color);
  text-decoration: none;
  display: block;
  padding: 10px;
}

.wrapper {
  position: relative;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.8);
}

/* ============================ */
/* = NOTIFICATION BOXES START = */
/* ============================ */

.messagebox {
  display: block;
  color: #222427;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-position: 10px center;
  border-radius: 4px;
  border: 1px solid #c2c4c7;
  padding: 10px 10px 10px 40px;
  margin: -15px 0 15px 0;
  opacity: 1;
  visibility: visible;
  transition: visibility 0.2s, opacity 0.2s;
}

.messagebox.hidden {
  padding: 0;
  margin: 0;
  opacity: 0;
  visibility: hidden;
}

.messagebox.error {
  background-image: url("../images/icon-error.svg");
  border: 1px solid #f1a3a3;
  background-color: #fae9e9;
}

.messagebox.success {
  background-image: url("../images/icon-success.svg");
  border: 1px solid #add39e;
  background-color: #e0efd9;
}

.messagebox.warning {
  background-image: url("../images/icon-warning.svg");
  border: 1px solid #d8cd85;
  background-color: #f8f3d6;
}

.messagebox.info {
  background-image: url("../images/icon-info.svg");
  border: 1px solid #a9ccdf;
  background-color: #deeff7;
}

.messagebox.pre-line {
  white-space: pre-line;
}

.messagebox.margin-top {
  margin-top: 10px;
}

.imagebox {
  display: block;
  margin: 0 auto;
}

.imagebox.hidden {
  padding: 0;
  margin: 0;
  opacity: 0;
  visibility: hidden;
}

.firefoxAllowCapture {
  background-image: url("../images/firefox-allow-capture.png");
  width: 400px;
  height: 271px;
}

/* =========================== */
/* = NOTIFICATION BOXES ENDE = */
/* =========================== */

/* ======================= */
/* = MEDIA QUERIES START = */
/* ======================= */

@media only screen and (max-width: 640px) {
  .modal-box {
    min-width: 96%;
    margin: 2%;
  }
}

/* ====================== */
/* = MEDIA QUERIES ENDE = */
/* ====================== */

.button-grid {
  /* display: flex;
  align-items: center;
  justify-content: space-around; */
  max-height: 360px;
  margin: 0px 15px;
  flex-wrap: wrap;
  color: #222427;
}

.image-button {
  position: relative;
  display: flex;
  justify-content: center;
  width: 150px;
  height: 150px;
  margin-bottom: 15px;
}

.image-button__image {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 80px;
  margin-top: 5px;
  border-radius: 50%;
  border: 1px solid #9ba2ac;
  cursor: pointer;
}

.image-button__image:hover {
  background-color: #9ba2ac;
}

.image-button__image:hover .image-button__image--dimension {
  filter: brightness(0) invert(1);
}

.image-button__text {
  position: absolute;
  text-align: center;
  bottom: 5px;
  color: #222427;
}

.image-button__image--dimension {
  background-repeat: no-repeat;
  height: 34px;
  width: 34px;
}

.welcome-box__icon-dialin {
  background: url(../images/icon-dial-in.svg);
}

.welcome-box__icon-dialout {
  background: url(../images/icon-dial-out.svg);
}

.welcome-box__icon-headset {
  background: url(../images/icon-headset.svg);
}

.welcome-box__icon-check {
  background: url(../images/icon-check.svg);
}

.grey-out {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(226, 226, 226, 0.7);
  z-index: 1;
  border-radius: 10px;
}

.grey-out__image {
  position: absolute;
  top: 10px;
  right: 10px;
  height: 24px;
  width: 24px;
  -webkit-mask: url(../images/icon-lock.svg);
  background-color: #9ba2ac;
}

/* ======================= */
/* =    IE11 fallback    = */
/* ======================= */

.z-layout-ie11 {
  z-index: 1;
}
