.star {
  padding: 5px;
  display: inline-block;
}

.star .star-bg {
  fill: #e2e4e7;
}

.star .star-border {
  fill: #cdced1;
}

.star.active .star-bg {
  fill: #fed600;
}

.star.active .star-border {
  fill: #e4c000;
}

.star:hover {
  cursor: pointer;
}
