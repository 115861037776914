.welcomescreen {
  height: 100%;
  /* width: calc(100% - 80px); */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #222427;
  left: 0;
  top: 0;
  position: fixed;
}

.welcomescreen-fullscreen {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;
  left: 0;
  top: 0;
  position: fixed;
}

.welcomebox {
  max-width: 680px;
  min-width: 500px;
  margin: 0 auto;
  display: block;
  text-align: center;
  position: relative;
  color: #c2c4c7;
}

h1 {
  margin: 0;
}

.icon-no-presentation {
  background: url(../images/icon-no-presentation.svg) no-repeat;
  width: 120px;
  height: 120px;
  display: inline-block;
  margin: 20px;
}

.actionbuttons {
  margin-top: 40px;
  display: inline-block;
}

.actionbuttons .action-button {
  width: 240px;
  border-radius: 4px;
  cursor: pointer;
  padding: 14px 0;
  transition: background-color 0.2s;
  margin-bottom: 10px;
}

.action-button p {
  margin: 0;
}

.icon-audio {
  background: url(../images/icon-audio.svg) no-repeat;
}

.icon-audio-fullscreen {
  background: url(../images/icon-audio-fullscreen.svg) no-repeat;
  bottom: 40px;
  position: absolute;
  height: 64px;
  width: 64px;
}

.icon-invite {
  background: url(../images/icon-invite.svg) no-repeat;
}

.icon-invite-fullscreen {
  background: url(../images/icon-add-participant-fullscreen.svg) no-repeat;
  bottom: 40px;
  position: absolute;
  height: 64px;
  width: 64px;
}

.icon-fullscreen {
  background: url(../images/icon-window-mode.svg) no-repeat;
  bottom: 40px;
  position: absolute;
  height: 64px;
  width: 64px;
}

/* ======================== */
/* = START SHARING BUTTON = */
/* ======================== */

.button-share {
  background-color: #a9de45;
  color: #222427;
}

.button-share:hover {
  background-color: #bae56a;
}

.button-share:active {
  background-color: #87b237;
}

/* ======================= */
/* = STOP SHARING BUTTON = */
/* ======================= */

.button-stop {
  background-color: #cd4236;
  color: #ffffff;
}

.button-stop:hover {
  background-color: #d2554a;
}

.button-stop:active {
  background-color: #a4352b;
}

.disabled {
  display: none;
  margin: 0 auto;
}

.presentation {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  transition: all 400ms;
}

.presentation--normal-view {
  right: 80px;
}

.presentation--fullscreen {
  right: 0;
}

.participantlist.shown + .presentation {
  right: 340px;
}

.screenPreview {
  margin: 60px auto 0px;
  max-width: 640px;
  height: 360px;
}

/* ======================= */
/* = CONNECTION INDICATOR = */
/* ======================= */
.connection-indicator {
  display: inline-block;
  border-radius: 50px;
  padding: 10px 20px;
  background-color: rgba(0, 0, 0, 0.15);
}

.connection-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 8px;
  margin-bottom: -0.15em;
}

.connection-indicator .warn {
  color: #cbad55;
}

.connection-indicator .error {
  color: #e26b6b;
}

.icon-poor-connection {
  background: url(../images/icon-poor-connection.svg) no-repeat;
}

.icon-no-connection {
  background: url(../images/icon-no-connection.svg) no-repeat;
}

.participant-pin-text {
  margin-top: 20px;
}

.welcomescreen__download-app-text {
  max-width: 450px;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
}

.welcomescreen__download-app-button {
  width: 200px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
}

/* ======================= */
/* = MEDIA QUERIES START = */
/* ======================= */

@media only screen and (max-width: 768px) {
  h1.welcome {
    font-size: 30px;
    font-weight: 100;
  }

  h2.welcome {
    font-size: 16px;
    font-weight: 100;
  }
}

/* ====================== */
/* = MEDIA QUERIES ENDE = */
/* ====================== */
