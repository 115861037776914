.btn {
  border-radius: 4px;
  text-align: center;
  min-width: 140px;
  padding: 10px;
  display: inline-block;
  cursor: pointer;
  box-shadow: none;
  transition: box-shadow 0.2s;
  border: 0px;
}

.btnlink {
  padding: 0px;
  margin: 0px;
}

.btn-grey {
  background-color: #9ba2ac;
  color: #ffffff;
}

.btn-cta {
  background-color: var(--actionbutton_background-color);
  color: var(--actionbutton_hover_background-color);
}

.btn:hover {
  box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.2);
}

.btn:focus,
.btn:active {
  box-shadow: inset 0 0 100px 100px rgba(0, 0, 0, 0.1);
}

label {
  text-align: left;
  display: block;
  margin-bottom: 5px;
}

.inputblock {
  display: inline-block;
  width: 100%;
}

.inputblock--big {
  margin-bottom: 14px;
}

.inputblock__child {
  text-align: left;
}

.inputblock__child:not(:last-child) {
  margin: 0 0 20px 0;
}

.input-field {
  border-radius: 4px;
  border: 1px solid #c2c4c7;
  font-size: 16px;
}

.input-field:checked + .slider {
  background-color: #a9de45;
}

.input-field:focus + .slider {
  box-shadow: 0 0 1px #a9de45;
}

.input-field:checked + .slider:before {
  -webkit-transform: translateX(22px);
  -ms-transform: translateX(22px);
  transform: translateX(22px);
}

.input-field--text {
  width: calc(100% - 20.467px);
  display: block;
  padding: 10px 2%;
}

.input-field--text:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset;
}

.input-field--submit {
  width: 462px;
  display: none;
  padding: 10px 2%;
}

.input-field--checkbox {
  width: 12px;
  display: inline-block;
  padding: 0;
  margin: 0;
}

.input-field--number {
  width: 462px;
  display: inline-block;
  padding: 10px 2%;
  -moz-appearance: textfield;
}

.input-field--number::-webkit-inner-spin-button,
.input-field--number::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.textarea-input {
  width: 462px;
  padding: 10px 2%;
  border-radius: 4px;
  border: 1px solid #c2c4c7;
  font-size: 16px;
  overflow: auto;
  vertical-align: top;
  resize: none;
  font-family: inherit;
  cursor: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
}

::placeholder {
  color: #999;
  font-weight: 100;
}

select {
  width: 100%;
  font-size: 14px;
  display: block;
}

/*
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
Toggle btn
https://www.w3schools.com/howto/tryit.asp?filename=tryhow_css_switch
*/

.switch {
  position: relative;
  width: 42px;
  height: 20px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #5d5f61;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

.left-right-container {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  width: 100%;
  margin-bottom: 14px;
  height: 38px;
}

.left {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 25%;
}
.right {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 75%;
}
