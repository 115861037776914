.c-image-button {
  width: 150px;
}

.c-image {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  border: 1px solid #9ba2ac;
  cursor: pointer;
}

.c-image:hover {
  background-color: #9ba2ac;
}

.c-image--clicked {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  border: 1px solid #9ba2ac;
  cursor: pointer;
  background-color: #9ba2ac;
}

.c-image:hover svg .st-conn-0 {
  fill: #f2f2f2;
}

.c-image--clicked svg .st-conn-0 {
  fill: #f2f2f2;
}

.c-name {
  margin-top: 16px;
  text-align: center;
  color: #222427;
}

.c-image__icon {
  margin: 23px;
  height: 34px;
  width: 34px;
}

.st-conn-0 {
  fill: #9ba2ac;
}
.st-conn-1 {
  display: none;
}
