.chat-message-container {
  margin-top: 10px;
  display: flex;
}

.chat-message-container:not(:last-child) {
  margin-bottom: 14px;
}

.chat-message-spacer {
  flex: 1 1 auto;
}

.chat-message {
  position: relative;
  border-radius: 4px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
  padding: 10px;
  flex: 0 0 auto;
  max-width: 270px;
  min-width: 150px;
  white-space: pre-line;
}

.chat-message::after {
  content: "";
  position: absolute;
  width: 0;
  border: 10px solid transparent;
  display: block;
}

.chat-message--outgoing {
  background-color: #4f5660;
  margin-right: 18px;
}

.chat-message--outgoing::after {
  top: 10px;
  bottom: auto;
  left: auto;
  right: -7px;
  border-width: 5px 0 5px 7px;
  border-color: transparent #4f5660;
}

.chat-message--incoming {
  background-color: #3c3e41;
  margin-left: 10px;
}

.chat-message--incoming::after {
  top: 10px;
  bottom: auto;
  left: -7px;
  border-width: 5px 7px 5px 0;
  border-color: transparent #3c3e41;
}

.chat-message__from {
  font-size: 14px;
  font-weight: bold;
  color: #a8b78e;
  margin-bottom: 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chat-message__message {
  font-size: 14px;
  line-height: 22px;
  color: #e2e4e7;
  overflow-wrap: break-word;
}

.chat-message__time {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  text-align: right;
}
