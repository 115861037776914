.chat-control {
  padding: 10px 20px;
  box-sizing: border-box;
  height: auto;
  min-height: 70px;
  max-height: 140px;
}
.chat-message-hint {
  height: 20px;
  width: 100%;
  margin-top: 5px;
  font-size: 13px;
  color: #c2c4c7;
  text-align: right;
}

.chat-message-input {
  height: auto;
  background-color: #3c3e41;
  border-radius: 4px;
  min-height: 45px;
}

.c-text-input {
  max-height: 120px;
  min-height: 50px;
}

.c-text-input__field {
  width: calc(100% - 24px);
  resize: none;
  background-color: #3c3e41;
  color: #e2e4e7;
  font-size: 14px;
  border: none;
  overflow-y: hidden !important;
  font-family: "Roboto", sans-serif;
  overflow-wrap: break-word;
  padding: 14px 0;
  margin: 0 14px;
  height: var(--chat-message-height);
}

.c-text-input__field--max-height-small {
  max-height: 68px;
}

.c-text-input__field--max-height-big {
  max-height: 90px;
}

::-webkit-scrollbar {
  width: 5px;
}

.chat-message-input__send-button {
  background: url(../../images/icon-send.svg);
  background-repeat: no-repeat;
  height: 18px;
  width: 18px;
  cursor: pointer;
  margin: 0 14px 14px 0;
}
