.header-block {
  height: 75px;
  margin: 20px 0px 0px 70px;
}

.header-block__logo {
  display: inline-block;
  width: 220px;
  content: var(--header_logo-url);
}

/* Firefox */
.header-block__logo::after {
  content: var(--header_logo-url);
}

@media only screen and (max-width: 699px) {
  .header-block {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0px 0px 0px;
  }
}

/* ======================= */
/* =    IE11 fallback    = */
/* ======================= */

.header {
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  height: 75px;
  margin: auto;
  padding: 0px 50px;
  z-index: 1;
}

.header .logo {
  display: inline-block;
  position: absolute;
  margin: 20px;
  width: 220px;
  content: var(--header_logo-url);
}

/* Firefox */
.header .logo::after {
  content: var(--header_logo-url);
}
