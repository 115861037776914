.information {
  position: absolute;
  width: 80px;
  height: 40px;
  z-index: 2;
  left: 20px;
  top: 14px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 20px;
}

.information-elements {
  margin: 12px;
}

.information-elements__rec-symbol {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #cd4236;
  margin-right: 12px;
}

.information-elements__rec-text {
  display: inline-block;
  vertical-align: top;
  font-size: 14px;
}
