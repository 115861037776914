@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700);
body {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
}

h1 {
  font-size: 50px;
  font-weight: 100;
}

h2 {
  font-size: 26px;
  font-weight: 100;
}

a {
  font-size: 16px;
  text-decoration: none;
  color: inherit;
}

a:visited {
  color: inherit;
}

p {
  font-size: 16px;
  line-height: 22px;
  margin-top: 0;
}

b {
  font-weight: 500;
}

/* ======================= */
/* = MEDIA QUERIES START = */
/* ======================= */

@media only screen and (max-width: 768px) {
  h1 {
    font-size: 30px;
    font-weight: 100;
  }

  h2 {
    font-size: 16px;
    font-weight: 100;
  }
}

/* ====================== */
/* = MEDIA QUERIES ENDE = */
/* ====================== */

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  color: #222427;
  display: flex;
  align-items: center;
  z-index: 1;
}

.modal-box {
  margin: 0 auto;
  padding: 0;
  background-color: #f2f2f2;
  border-radius: 4px;
  overflow: auto;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.5);
  position: relative;
}

.modal-box--big {
  min-width: 510px;
  max-width: 520px;
}

.modal-box--small {
  min-width: 315px;
  max-width: 325px;
}

.modal-top {
  background-color: #e2e4e7;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 24px;
  text-align: left;
}

.modal-bottom {
  display: block;
}

.modal-bottom--big {
  padding: 34px 24px 50px;
}

.modal-bottom--small {
  padding: 25px 24px 25px;
}

.icon-close {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  background-image: url(../../static/media/icon-close-lightbox.03946bcf.svg);
  display: block;
  height: 14px;
  width: 14px;
  opacity: 0.5;
  transition: opacity 0.2s;
}

.icon-close:hover {
  opacity: 1;
}

.inputblock a {
  color: blue;
}

/* ================== */
/* = FEEDBACK START = */
/* ================== */

.feedbackblock {
  display: flex;
  /* width: 180px; */
  margin: 14px 0 24px 0;
}

.feedbackblock a {
  display: block;
  height: 30px;
  width: 30px;
  fill: #c2c4c7;
  flex: 1 1;
  transition: fill 0.2s;
}

.feedbackblock a:hover,
.feedbackblock a:active,
.feedbackblock a:focus {
  fill: #fed30b;
}

/* label.callback {
  display: inline-block;
  margin-left: 10px;
} */

/* ================= */
/* = FEEDBACK ENDE = */
/* ================= */

.callbackblock {
  display: block;
}

#callback {
  display: inline-block;
}

.btn-wrapper {
  display: flex;
}

.btn-wrapper--margin-top {
  margin-top: 30px;
}

.btn-wrapper--margin-top-small {
  margin-top: 16px;
}

.btn {
  flex: 1 1;
}

.btn-grey {
  margin-right: 1%;
}

.btn-cta {
  margin-left: 1%;
}

.btn-cta_link {
  color: var(--actionbutton_hover_background-color);
  text-decoration: none;
  display: block;
  padding: 10px;
}

.wrapper {
  position: relative;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.8);
}

/* ============================ */
/* = NOTIFICATION BOXES START = */
/* ============================ */

.messagebox {
  display: block;
  color: #222427;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-position: 10px center;
  border-radius: 4px;
  border: 1px solid #c2c4c7;
  padding: 10px 10px 10px 40px;
  margin: -15px 0 15px 0;
  opacity: 1;
  visibility: visible;
  transition: visibility 0.2s, opacity 0.2s;
}

.messagebox.hidden {
  padding: 0;
  margin: 0;
  opacity: 0;
  visibility: hidden;
}

.messagebox.error {
  background-image: url(../../static/media/icon-error.eb167d4b.svg);
  border: 1px solid #f1a3a3;
  background-color: #fae9e9;
}

.messagebox.success {
  background-image: url(../../static/media/icon-success.44ba51d4.svg);
  border: 1px solid #add39e;
  background-color: #e0efd9;
}

.messagebox.warning {
  background-image: url(../../static/media/icon-warning.89680576.svg);
  border: 1px solid #d8cd85;
  background-color: #f8f3d6;
}

.messagebox.info {
  background-image: url(../../static/media/icon-info.dd587e21.svg);
  border: 1px solid #a9ccdf;
  background-color: #deeff7;
}

.messagebox.pre-line {
  white-space: pre-line;
}

.messagebox.margin-top {
  margin-top: 10px;
}

.imagebox {
  display: block;
  margin: 0 auto;
}

.imagebox.hidden {
  padding: 0;
  margin: 0;
  opacity: 0;
  visibility: hidden;
}

.firefoxAllowCapture {
  background-image: url(../../static/media/firefox-allow-capture.a3cddb56.png);
  width: 400px;
  height: 271px;
}

/* =========================== */
/* = NOTIFICATION BOXES ENDE = */
/* =========================== */

/* ======================= */
/* = MEDIA QUERIES START = */
/* ======================= */

@media only screen and (max-width: 640px) {
  .modal-box {
    min-width: 96%;
    margin: 2%;
  }
}

/* ====================== */
/* = MEDIA QUERIES ENDE = */
/* ====================== */

.button-grid {
  /* display: flex;
  align-items: center;
  justify-content: space-around; */
  max-height: 360px;
  margin: 0px 15px;
  flex-wrap: wrap;
  color: #222427;
}

.image-button {
  position: relative;
  display: flex;
  justify-content: center;
  width: 150px;
  height: 150px;
  margin-bottom: 15px;
}

.image-button__image {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 80px;
  margin-top: 5px;
  border-radius: 50%;
  border: 1px solid #9ba2ac;
  cursor: pointer;
}

.image-button__image:hover {
  background-color: #9ba2ac;
}

.image-button__image:hover .image-button__image--dimension {
  -webkit-filter: brightness(0) invert(1);
          filter: brightness(0) invert(1);
}

.image-button__text {
  position: absolute;
  text-align: center;
  bottom: 5px;
  color: #222427;
}

.image-button__image--dimension {
  background-repeat: no-repeat;
  height: 34px;
  width: 34px;
}

.welcome-box__icon-dialin {
  background: url(../../static/media/icon-dial-in.f36e2879.svg);
}

.welcome-box__icon-dialout {
  background: url(../../static/media/icon-dial-out.dd81c770.svg);
}

.welcome-box__icon-headset {
  background: url(../../static/media/icon-headset.f5c77ff8.svg);
}

.welcome-box__icon-check {
  background: url(../../static/media/icon-check.1c396072.svg);
}

.grey-out {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(226, 226, 226, 0.7);
  z-index: 1;
  border-radius: 10px;
}

.grey-out__image {
  position: absolute;
  top: 10px;
  right: 10px;
  height: 24px;
  width: 24px;
  -webkit-mask: url(../../static/media/icon-lock.ecfff62d.svg);
  background-color: #9ba2ac;
}

/* ======================= */
/* =    IE11 fallback    = */
/* ======================= */

.z-layout-ie11 {
  z-index: 1;
}

.sidebar {
  display: none;
}

.fullscreen-controls {
  width: 80px;
  margin: 0;
  top: 20px;
  display: block;
  position: fixed;
  right: 0;
  height: 100%;
}
.fullscreen-controls{
  min-width: 80px;
  min-height: 100%;
}

.fullscreen-controls ul {
  height: 100%;
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: absolute;
}

.fullscreen-controls ul li,
.fullscreen-controls.mouse-inactive:hover ul li {
  width: 60px;
  background-color: #323437;
  opacity: 0.75;
  transition: opacity 0.3s;
}

.fullscreen-controls.mouse-inactive ul li {
  opacity: 0;
}

.fullscreen-controls.mouse-inactive ul li:hover,
.fullscreen-controls ul li:hover {
  opacity: 1;
}

.fullscreen-controls li.sidebar-item {
  position: relative;
  cursor: pointer;
  padding: 13px 0;
  border-radius: 50px;
  margin-top: 20px;
}

.fullscreen.mouse-inactive {
  cursor: none;
}

.fullscreen-controls li > span {
  margin: 0 auto;
  width: 34px;
  height: 34px;
  background-repeat: no-repeat;
  display: block;
}

.fullscreen-controls li span.action-participantlist {
  background: url(../../static/media/icon-participant-list.04d716c6.svg);
}

.fullscreen-controls li span.action-invite {
  background: url(../../static/media/icon-add-participant.4f1da41d.svg);
}

.fullscreen-controls li span.action-audio {
  background: url(../../static/media/icon-phone.c4644b17.svg);
}

.fullscreen-controls li span.action-chat {
  background: url(../../static/media/icon-chat.4aacf356.svg);
}

.fullscreen-controls li span.action-settings {
  background: url(../../static/media/icon-settings.88f14d78.svg);
}

.fullscreen-controls li span.action-quit {
  background: url(../../static/media/icon-logout.72a7f4c0.svg);
}

.fullscreen-controls li span.action-usermenu .dropbtn {
  background: url(../../static/media/icon-dots.eefa0dd7.svg);
  height: 34px;
  width: 34px;
}

.fullscreen-controls li span.action-exit-fullscreen {
  background: url(../../static/media/icon-window-mode.c00005a1.svg);
  height: 34px;
  width: 34px;
}

.fullscreen-controls li.exitfullscreen {
  bottom: 200px;
  position: absolute;
}

.fullscreen-controls li.settings {
  bottom: 120px;
  position: absolute;
}

.fullscreen-controls li.quit {
  bottom: 40px;
  position: absolute;
}

.fullscreen-controls li.participantlistfullscreen {
  /* margin-top: 40px; */
}

.participantlist {
  right: -340px;
}

.participantlist.shown {
  right: 0px !important;
}

.tooltip .tooltiptext {
  right: 75px;
}

.sidebar__top-button--fullscreen-margin {
  margin-top: 40px;
}

.sidebar__middle-button--fullscreen-margin {
  margin-top: 20px;
}

.welcomescreen {
  height: 100%;
  /* width: calc(100% - 80px); */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #222427;
  left: 0;
  top: 0;
  position: fixed;
}

.welcomescreen-fullscreen {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;
  left: 0;
  top: 0;
  position: fixed;
}

.welcomebox {
  max-width: 680px;
  min-width: 500px;
  margin: 0 auto;
  display: block;
  text-align: center;
  position: relative;
  color: #c2c4c7;
}

h1 {
  margin: 0;
}

.icon-no-presentation {
  background: url(../../static/media/icon-no-presentation.64a6e01f.svg) no-repeat;
  width: 120px;
  height: 120px;
  display: inline-block;
  margin: 20px;
}

.actionbuttons {
  margin-top: 40px;
  display: inline-block;
}

.actionbuttons .action-button {
  width: 240px;
  border-radius: 4px;
  cursor: pointer;
  padding: 14px 0;
  transition: background-color 0.2s;
  margin-bottom: 10px;
}

.action-button p {
  margin: 0;
}

.icon-audio {
  background: url(../../static/media/icon-audio.100822b4.svg) no-repeat;
}

.icon-audio-fullscreen {
  background: url(../../static/media/icon-audio-fullscreen.c8403d87.svg) no-repeat;
  bottom: 40px;
  position: absolute;
  height: 64px;
  width: 64px;
}

.icon-invite {
  background: url(../../static/media/icon-invite.86b0e8c7.svg) no-repeat;
}

.icon-invite-fullscreen {
  background: url(../../static/media/icon-add-participant-fullscreen.1989917c.svg) no-repeat;
  bottom: 40px;
  position: absolute;
  height: 64px;
  width: 64px;
}

.icon-fullscreen {
  background: url(../../static/media/icon-window-mode.c00005a1.svg) no-repeat;
  bottom: 40px;
  position: absolute;
  height: 64px;
  width: 64px;
}

/* ======================== */
/* = START SHARING BUTTON = */
/* ======================== */

.button-share {
  background-color: #a9de45;
  color: #222427;
}

.button-share:hover {
  background-color: #bae56a;
}

.button-share:active {
  background-color: #87b237;
}

/* ======================= */
/* = STOP SHARING BUTTON = */
/* ======================= */

.button-stop {
  background-color: #cd4236;
  color: #ffffff;
}

.button-stop:hover {
  background-color: #d2554a;
}

.button-stop:active {
  background-color: #a4352b;
}

.disabled {
  display: none;
  margin: 0 auto;
}

.presentation {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  transition: all 400ms;
}

.presentation--normal-view {
  right: 80px;
}

.presentation--fullscreen {
  right: 0;
}

.participantlist.shown + .presentation {
  right: 340px;
}

.screenPreview {
  margin: 60px auto 0px;
  max-width: 640px;
  height: 360px;
}

/* ======================= */
/* = CONNECTION INDICATOR = */
/* ======================= */
.connection-indicator {
  display: inline-block;
  border-radius: 50px;
  padding: 10px 20px;
  background-color: rgba(0, 0, 0, 0.15);
}

.connection-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 8px;
  margin-bottom: -0.15em;
}

.connection-indicator .warn {
  color: #cbad55;
}

.connection-indicator .error {
  color: #e26b6b;
}

.icon-poor-connection {
  background: url(../../static/media/icon-poor-connection.303f376c.svg) no-repeat;
}

.icon-no-connection {
  background: url(../../static/media/icon-no-connection.f788202a.svg) no-repeat;
}

.participant-pin-text {
  margin-top: 20px;
}

.welcomescreen__download-app-text {
  max-width: 450px;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
}

.welcomescreen__download-app-button {
  width: 200px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
}

/* ======================= */
/* = MEDIA QUERIES START = */
/* ======================= */

@media only screen and (max-width: 768px) {
  h1.welcome {
    font-size: 30px;
    font-weight: 100;
  }

  h2.welcome {
    font-size: 16px;
    font-weight: 100;
  }
}

/* ====================== */
/* = MEDIA QUERIES ENDE = */
/* ====================== */

.main {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.main--ie11-fix {
  height: 0px;
}

.main-background {
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    var(--login_background);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.main-background-blured {
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    var(--login_background);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  -webkit-filter: blur(var(--blur-value));
          filter: blur(var(--blur-value));
}

.bg-blur
  div:not(.modal-container)
  div:not(.header-block)
  div:not(.footer-block) {
  -webkit-filter: blur(var(--blur-value));
          filter: blur(var(--blur-value));
}

.hiddeniframe {
  display: none;
}

/* ======================= */
/* =    IE11 fallback    = */
/* ======================= */

.App {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.App:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--login_background_image_shade);
}

.App-background {
  background: var(--login_background);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.App-background-blured {
  background: var(--login_background);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  -webkit-filter: blur(var(--blur-value));
          filter: blur(var(--blur-value));
}

:root {
  --blur-value: 5px;
  --sidebar-content-transition-time: 500ms;
  --chat-message-height: 17px;
}

.main-block {
  flex: 1 1 auto;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.start-block {
  max-width: 500px;
  text-align: center;
  padding-bottom: 45px;
}

.start-block__left {
  display: inline-block;
  width: 40%;
  cursor: pointer;
  margin: 0 5%;
}

.start-block__right {
  display: inline-block;
  width: 40%;
  cursor: pointer;
  margin: 0 5%;
}

.start-block__label {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 24px;
  text-align: center;
  margin-top: 30px;
  color: var(--actionbutton_text-color);
}
.start-block__buyproduct {
  margin-top: 80px;
  padding: 20px 30px;
  border: 2px solid #666;
  border-radius: 5px;
  display: inline-block;
}

.start-block__buyproduct-link {
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  color: #aaa;
}

.start-block__buyproduct-link:visited {
  color: #aaa;
}

.starticon {
  width: 140px;
  height: 140px;
  margin: 0 auto;
}

svg circle {
  transition: fill 0.2s;
  /* transition: fill-opacity 0.5s; */
}

svg:hover circle.st0 {
  fill: var(--actionbutton_background-color-hover);
}

svg:focus circle,
svg:active circle {
  fill: var(--actionbutton_background-color-pushed);
}

svg .st0 {
  fill: var(--actionbutton_background-color);
}

svg .st1 {
  fill: var(--actionbutton_hover_background-color);
}

@media only screen and (max-width: 699px) {
  .start-block__label {
    font-size: 20px;
    margin-top: 30px;
  }
}

/* ======================= */
/* =    IE11 fallback    = */
/* ======================= */

.main-block-ie11 {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.start-block-ie11 {
  max-width: 500px;
  text-align: center;
}

/* ================ */
/* = BODY - START = */
/* ================ */

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  color: #ffffff;
  background-color: #222427;
}

:focus {
  outline: none;
}

/* =============== */
/* = BODY - ENDE = */
/* =============== */

/* =================== */
/* = TOOLTIP - START = */
/* =================== */

.tooltip .tooltiptext {
  display: inline-block;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 4px;
  visibility: hidden;
  font-size: 14px;
  color: #fff;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.5s;
  transition-delay: 0.5s;
}

.tooltip__text--sidebar-button-adjustment {
  right: 95px;
  margin-top: 3px;
  padding: 6px 10px;
}

.tooltip__text--participant-list-settings-adjustment {
  right: 30px;
  margin-top: -6px;
  padding: 6px 10px;
}

.tooltip__text--conf-control-adjustment {
  right: 80px;
  margin-top: 18px;
  padding: 6px 10px;
}

.tooltip__text--help-button-adjustment {
  left: 70px;
  bottom: 13px;
  padding: 6px 10px;
}

/* tooltip arrow */
.tooltip .tooltiptext--right:before {
  content: "";
  display: block;
  position: absolute;
  right: -4px;
  top: 10px;
  -ms-transform: rotate(45deg);
      transform: rotate(45deg);
  width: 10px;
  height: 10px;
  background-color: inherit;
}

/* tooltip arrow */
.tooltip .tooltiptext--left:before {
  content: "";
  display: block;
  position: absolute;
  left: -4px;
  top: 10px;
  -ms-transform: rotate(45deg);
      transform: rotate(45deg);
  width: 10px;
  height: 10px;
  background-color: inherit;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

/* ================== */
/* = TOOLTIP - ENDE = */
/* ================== */

.centerer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.creds-input {
  margin-bottom: 14px;
  display: inline-block;
  width: 100%;
}

.creds-input__pin-label {
  text-align: left;
  display: inline-block;
  margin-bottom: 5px;
  margin-right: 6px;
}

.creds-input__info-icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-image: url(../../static/media/icon-tooltip.ccc3a070.svg);
  width: 20px;
  height: 20px;
  position: relative;
  top: 3px;
  cursor: pointer;
  margin-right: 6px;
}

.creds-input__tooltip {
  background-color: rgba(0, 0, 0, 0.7);
  min-width: 170px;
  border-radius: 4px;
  padding: 6px 10px;
  margin-left: 25px;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s;
  transition-delay: 0.5s;
  position: absolute;
  cursor: default;
  /* white-space: pre-line; */
}

.creds-input__info-icon:hover .creds-input__tooltip {
  visibility: visible;
  opacity: 1;
}

.electron-select {
  width: 620px;
  margin: 0 auto;
  padding: 0;
  background-color: #f2f2f2;
  border-radius: 4px;
  overflow: auto;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.5);
  position: relative;
}

.electron-thumbnails {
  display: flex;
  max-height: 360px;
  margin: 24px 24px 0px 24px;
  flex-wrap: wrap;
  /* justify-content: space-around; */
  overflow-y: auto;
}

.electron-thumbnail {
  position: relative;
  margin-bottom: 14px;
  margin-left: 9px;
  width: 160px;
  height: 165px;
  padding: 8px;
}

.electron-thumbnail:hover::after {
  content: "\A";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  border: 2px solid #abdc4f;
  box-sizing: border-box;

  /* border: 4px solid #abdc4f;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; */

  /* top: -9px;
  left: -8px;
  width: 110%;
  height: 110%;
  border-radius: 4px;
  box-shadow: 1px 4px 10px #222427;
  opacity: 0.5;
  background: #abdc4f; */
}

.electron-thumbnail--border {
  content: "\A";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  border: 2px solid #abdc4f;
  box-sizing: border-box;
  padding: 6px;
}

.electron-thumbnail__image {
  height: 142px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.electron-thumbnail__title {
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  margin-top: 4px;
}

.tooltip__text--dialout-menu-entry {
  right: 95px;
  margin-top: 3px;
  padding: 6px 10px;
}

.header-block {
  height: 75px;
  margin: 20px 0px 0px 70px;
}

.header-block__logo {
  display: inline-block;
  width: 220px;
  content: var(--header_logo-url);
}

/* Firefox */
.header-block__logo::after {
  content: var(--header_logo-url);
}

@media only screen and (max-width: 699px) {
  .header-block {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0px 0px 0px;
  }
}

/* ======================= */
/* =    IE11 fallback    = */
/* ======================= */

.header {
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  height: 75px;
  margin: auto;
  padding: 0px 50px;
  z-index: 1;
}

.header .logo {
  display: inline-block;
  position: absolute;
  margin: 20px;
  width: 220px;
  content: var(--header_logo-url);
}

/* Firefox */
.header .logo::after {
  content: var(--header_logo-url);
}

.footer-block {
  min-height: 50px;
  margin: auto;
}

.footer-info {
  text-align: center;
}

.footer-info__link,
.footer-info__link:visited {
  margin: 0 20px;
  color: #c2c4c7;
  transition: color 0.5s;
}

.footer-info__link:hover,
.footer-info__link:active {
  color: #ffffff;
}

@media only screen and (max-width: 699px) {
  .footer-block {
    min-height: 220px;
  }
  .footer-info__link {
    margin: 20px 0;
    display: block;
  }
}

/* ======================= */
/* =    IE11 fallback    = */
/* ======================= */

.footer-block-ie11 {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 50px;
  margin: auto;
  padding: 0px 50px;
  z-index: 1;
}

.footer-info__link-ie11,
.footer-info__link-ie11:visited {
  margin: 0 20px;
  color: #c2c4c7;
  transition: color 0.5s;
}

.footer-info__link-ie11:hover,
.footer-info__link-ie11:active {
  color: #ffffff;
}

@media only screen and (max-width: 699px) {
  .footer-ie11 {
    height: 220px;
  }
  .footer-info__link-ie11 {
    margin: 20px 0;
    display: block;
  }
}

/* =================== */
/* = SIDEBAR - START = */
/* =================== */

.sidebar {
  width: 80px;
  margin: 0;
  top: 0;
  display: block;
  position: fixed;
  right: 0;
  height: 100%;
  background-color: #323437;
  box-shadow: -3px 0px 10px 0 rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.sidebar ul {
  width: 80px;
  height: 100%;
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: absolute;
}

.sidebar ul li {
  width: 80px;
  background-color: none;
  transition: background-color 0.5s;
  /* transition: all ease-out 120ms; */
}

.sidebar ul li:hover,
.sidebar ul li.active,
.sidebar ul li.dropDownOpened {
  background-color: #3c3e41;
}

.sidebar ul li.userprofile:hover {
  background: #929497;
}

.sidebar-item__button{
  padding: 23px 0;
  margin: -23px 0;
  align-items: center;
  max-width: 80px;
}

.sidebar-item__button--fullscreen{
  padding: 23px 0;
  margin: -23px 0;
  align-items: center;
  max-width: 80px;
}

.sidebar li.sidebar-item {
  position: relative;
  cursor: pointer;
  padding: 23px 0;
}

.sidebar li span {
  margin: 0 auto;
  width: 34px;
  background-repeat: no-repeat;
  display: block;
}
.sidebar-item__children {
  display: block;
  height: 34px;
  width:34px;
  
  margin-left: auto;
  margin-right: auto;
}

.sidebar li span span {
  margin: 0;
  min-width: 220px;
  color: #c2c4c7;
  padding: 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.sidebar li span span a {
  color: #c2c4c7;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.sidebar li.userprofile {
  height: 60px;
  width: 60px;
  margin: 10px;
  padding: 0;
  top: 0;
  background-color: #929497;
  border-radius: 50px;
  cursor: default;
}

.sidebar h2 {
  color: #323437;
  margin: 0;
  padding: 14px 0 0;
}

.sidebar li.sidebar-item.fullscreen {
  position: absolute;
  /* bottom: 80px; */
  bottom: 160px;
  height: 34px;
}

.sidebar li.sidebar-item.settings {
  position: absolute;
  bottom: 80px;
  height: 34px;
}

.sidebar li.sidebar-item.quit {
  position: absolute;
  bottom: 0;
  height: 34px;
}

.sidebar li span.action-participantlist {
  background: url(../../static/media/icon-participant-list.04d716c6.svg);
  height: 34px;
}

.sidebar li span.action-invite {
  background: url(../../static/media/icon-add-participant.4f1da41d.svg);
  height: 34px;
}

.sidebar li span.action-audio {
  background: url(../../static/media/icon-phone.c4644b17.svg);
  height: 34px;
}

.sidebar li span.action-fullscreen {
  background: url(../../static/media/icon-fullscreen.8be22f3e.svg);
  height: 34px;
}

.sidebar li span.action-settings {
  background: url(../../static/media/icon-settings.88f14d78.svg);
  height: 34px;
}

.sidebar li span.action-quit {
  background: url(../../static/media/icon-logout.72a7f4c0.svg);
  height: 34px;
}

.sidebar li span.action-chat {
  background: url(../../static/media/icon-chat.4aacf356.svg);
  height: 34px;
}

.sidebar-item-badge__border {
  position: absolute;
  background-color: transparent;
  border: 13px solid;
  border-right-color: transparent;
  border-top-color: transparent;
  border-bottom-color: #323437;
  border-left-color: #323437;
  border-radius: 50%;
  transition: border 0.5s;
}

.sidebar-item-badge__border--norml-view {
  top: 8px;
  right: 8px;
}

.sidebar-item-badge__border--fullscreen {
  top: -1px;
  right: -1px;
}

.sidebar-item-badge__content {
  position: absolute;
  background-color: #cd4236;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  width: 20px;
  height: 20px;
  line-height: 22px;
  border-radius: 50%;
  text-align: center;
}

.sidebar-item-badge__content--norml-view {
  top: 11px;
  right: 11px;
}

.sidebar-item-badge__content--fullscreen {
  top: 2px;
  right: 2px;
}

.sidebar ul li:hover .sidebar-item-badge__border {
  border-bottom-color: #3c3e41;
  border-left-color: #3c3e41;
}

.sidebar li span.action-usermenu .dropbtn {
  background: url(../../static/media/icon-dots.eefa0dd7.svg);
  height: 34px;
  width: 34px;
}

/* ================== */
/* = SIDEBAR - ENDE = */
/* ================== */

/* =========================== */
/* = PARTICIPANTLIST - START = */
/* =========================== */

.participantlist {
  position: fixed;
  width: 340px;
  top: 0;
  right: -260px;
  height: 100%;
  background-color: #2d2f31;
  box-shadow: -3px 0px 10px 0 rgba(0, 0, 0, 0.3);
  transition: right var(--sidebar-content-transition-time);
  z-index: 1;
}

.participantlist.shown {
  right: 80px;
}
.participants {
  overflow: auto;
  height: 100%;
}

.participant-presenting {
  left: 10px;
  top: 21px;
  position: absolute;
}

.participant-presenting span.icon {
  position: absolute;
  mask: url(../../static/media/icon-presenting.45f0ef71.svg);
  -webkit-mask: url(../../static/media/icon-presenting.45f0ef71.svg);
  background-color: #c2c4c7;
  height: 16px;
  width: 16px;
}

.participant-presenting.active span.icon {
  background-color: #a9de45;
}

.participant-presenting .tooltiptext {
  display: inline-block;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 6px 10px;
  border-radius: 4px;
  right: 34px;
  margin-top: -8px;
  visibility: hidden;
  font-size: 14px;
  color: #fff;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.5s;
  transition-delay: 0.5s;
}

.participant-host {
  color: #8e9fb7;
  font-size: 14px;
}

.participant {
  border-bottom: solid 1px #222427;
  padding: 20px 20px 20px 40px;
  color: #c2c4c7;
  position: relative;
  background-color: none;
  transition: background-color 0.3s;
}

.participant:hover {
  background-color: #222427;
}

.participant-menu {
  float: right;
  top: 14px;
  position: absolute;
  right: 10px;
  padding: 0;
  margin: 0;
}

.participant-menu .dropbtn {
  background: url(../../static/media/icon-dots.eefa0dd7.svg);
  height: 22px;
  width: 22px;
  top: -6px;
  right: 10px;
  position: absolute;
}

.participant-menu .dropbtn:hover {
  cursor: pointer;
  position: absolute;
}

.participant-name {
  padding-bottom: 3px;
  width: 240px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.participantlist-header {
  min-height: 85px;
  background-color: #222427;
  border-bottom: solid 1px #222427;
  padding-bottom: 20px;
}

.participantlist-close {
  background: url(../../static/media/icon-close.476d188f.svg);
  display: inline-block;
  height: 14px;
  width: 14px;
  margin: 20px 0 0 20px;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.2s;
}

.participantlist-close:hover {
  opacity: 1;
}

.participantlist-title {
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  margin: 0 0 6px 0;
  color: #c2c4c7;
  display: inline-block;
}

.participantlist-count {
  width: 100%;
  text-align: center;
  color: #c2c4c7;
}

/* ========================== */
/* = PARTICIPANTLIST - ENDE = */
/* ========================== */

.sidebar-entry-container {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  width: 100%;
}

.sidebar-entry-container--participant {
  height: 80px;
  color: #c2c4c7;
}

.sidebar-entry-container--header {
  height: 40px;
  background-color: #3c3e41;
  color: #7d7f81;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border-bottom: solid 1px #2d2f31;
}

.sidebar-entry-container-divider {
  border-bottom: solid 1px #222427;
}

.sidebar-entry-content-divider {
  margin-top: 5px;
  margin-bottom: 5px;
}

.sidebar-entry-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 75%;
  padding: 10px 20px;
}

.sidebar-entry-content-title {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: baseline;
}

.sidebar-entry-content-title__text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sidebar-entry-content-title--host {
  color: #8e9fb7;
  font-size: 14px;
  bottom: 0px;
}

.sidebar-entry-content-symbols {
  width: 100%;
}

.sidebar-entry-content-symbols__placeholder {
  display: inline-block;
  height: 16px;
  width: 0px;
}

.sidebar-entry-content-symbols__presenting-inline {
  display: inline-block;
  fill: #c2c4c7;
  height: 16px;
  width: 16px;
  margin-right: 7px;
}

.sidebar-entry-content-symbols__presenting-inline--active {
  fill: #a9de45;
}

.sidebar-entry-content-symbols__on-hold-inline {
  display: inline-block;
  fill: #c2c4c7;
  height: 16px;
  width: 16px;
  margin-right: 7px;
}

.sidebar-entry-content-symbols__mute-inline {
  display: inline-block;
  fill: #e26b6b;
  height: 16px;
  width: 16px;
  margin-right: 7px;
}

.sidebar-entry-content-symbols__active-speaker-inline {
  display: inline-block;
  fill: #a9de45;
  height: 16px;
  width: 16px;
  margin-right: 7px;
}

.sidebar-entry-symbols {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
}

.sidebar-entry-symbols__dropbtn {
  height: 22px;
  width: 22px;
  /* opacity: 0;
  transition: opacity 250ms ease-in; */
}

/* .sidebar-entry-container--participant:hover .sidebar-entry-symbols__dropbtn {
  opacity: 1;
  transition: opacity 250ms ease-out;
} */

.sidebar-entry-symbols__arrow {
  width: 7px;
  height: 7px;
  border-radius: 1px;
  border-color: #7d7f81;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-right-style: solid;
  border-right-width: 2px;
}

.sidebar-entry-symbols__arrow--up {
  -ms-transform: rotate(225deg);
      transform: rotate(225deg);
}

.sidebar-entry-symbols__arrow--down {
  -ms-transform: rotate(45deg);
      transform: rotate(45deg);
}

.sidebar-entry-symbols__arrow--closing {
  -ms-transform: rotate(-315deg);
      transform: rotate(-315deg);
  transition: transform 500ms ease;
}

.sidebar-entry-symbols__arrow--opening {
  -ms-transform: rotate(-135deg);
      transform: rotate(-135deg);
  transition: transform 500ms ease;
}

.user-menu__symbol {
  background: url(../../static/media/icon-dots.eefa0dd7.svg);
  height: 34px;
  width: 34px;
}

.hidden {
  display: none;
}

.sidebar-settings-list {
  overflow: hidden;
  height: 100%;
  padding: 10px 20px;
  color: #c2c4c7;
}

.dropdown {
  position: relative;
  display: inline-block;
  text-align: left;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  /* position: relative;
  right: 200px; */
  background-color: #323437;
  border-radius: 4px;
  min-width: 225px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.dropdown-content-up {
  position: absolute;
  right: 0px;
  bottom: var(--bottom-spacing);
}

.dropdown-content-down {
  position: absolute;
  right: 0px;
  top: 40px;
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content span {
  color: #c2c4c7;
  padding: 16px;
  text-decoration: none;
  display: block;
}

/* Fix for embedded a tags */
.dropdown-content span a {
  display: block;
  margin: -16px;
  padding: 16px;
}

/* Change color of dropdown links on hover */
.dropdown-content span:hover {
  background-color: #404245;
  color: #ffffff;
  cursor: pointer;
}

.dropdown-content span:first-of-type {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.dropdown-content span:last-of-type {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.dropdown-button {
  position: relative;
  background: url(../../static/media/icon-dots.eefa0dd7.svg);
  /* background: no-repeat; */
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
  height: 100%;
}

.dropdown-button--no-symbol {
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
  height: 100%;
}

.dropdown-content-entry {
  position: relative;
  /* display: block;
  color: #c2c4c7;
  padding: 16px;
  text-decoration: none; */
}

.dropdown-content-entry--link-fix {
  display: block;
  margin: -16px;
  padding: 16px;
}

.dropdown-content-entry:hover {
  background-color: #404245;
  color: #ffffff;
  cursor: pointer;
}

.dropdown-content-entry:first-of-type {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.dropdown-content-entry:last-of-type {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.dropdown-content-entry__grey-out {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(226, 226, 226, 0.7);
}

.dropdown-content-entry__text {
  display: block;
  color: #c2c4c7;
  padding: 16px;
  text-decoration: none;
}

.dropdown-content-entry__grey-out-image {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 12px 112px 12px 112px;
  height: 28px;
  width: 28px;
  -webkit-mask: url(../../static/media/icon-lock.ecfff62d.svg);
  background-color: #404245;
}

.control {
  position: absolute;
  width: 140px;
  height: 60px;
  left: 50%;
  -ms-transform: translate(-50%, 0);
      transform: translate(-50%, 0);
}

.control--normalsize {
  bottom: 40px;
}

.control--fullscreen {
  bottom: 100px;
}

.control-action {
  position: relative;
  display: inline-block;
  cursor: pointer;
  width: 60px;
  height: 100%;
  border-radius: 50%;
}

.control-action--mute {
  background-color: #323437;
  opacity: 0.75;
  transition: opacity 250ms ease-in;
}

.control-action--mute:hover {
  opacity: 1;
  transition: opacity 250ms ease-out;
}

.control-action--hangup {
  background-color: #cd4236;
}

.control-action__placeholder {
  width: 20px;
  cursor: default;
}

.control-action__element {
  position: absolute;
  height: 34px;
  width: 34px;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
  background-color: #ffffff;
}

.control-action__element--unmuted {
  -webkit-mask: url(../../static/media/icon-micro-on.d1f3878e.svg);
}

.control-action__element--muted {
  -webkit-mask: url(../../static/media/icon-micro-off.9c4f1b4f.svg);
}

.control-action__element--hangup {
  -webkit-mask: url(../../static/media/icon-hang-up.c772f0ae.svg);
}

.information {
  position: absolute;
  width: 80px;
  height: 40px;
  z-index: 2;
  left: 20px;
  top: 14px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 20px;
}

.information-elements {
  margin: 12px;
}

.information-elements__rec-symbol {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #cd4236;
  margin-right: 12px;
}

.information-elements__rec-text {
  display: inline-block;
  vertical-align: top;
  font-size: 14px;
}

.c-help {
  position: absolute;
  left: 40px;
  bottom: 40px;
  height: 60px;
  width: 60px;
  background-color: #323437;
  opacity: 0.75;
  border-radius: 50%;
  cursor: pointer;
}

.c-help:hover {
  opacity: 1;
}

.c-help-symbol {
  height: 28px;
  width: 28px;
  margin: 16px;
  background-color: #ffffff;
  background: url(../../static/media/icon-help.9ca7c758.svg);
}

.sidebar-list {
  position: fixed;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  width: 340px;
  top: 0;
  height: 100%;
  background-color: #2d2f31;
  box-shadow: -3px 0px 10px 0 rgba(0, 0, 0, 0.3);
  transition: right var(--sidebar-content-transition-time);
  z-index: 1;
}

.sidebar-list--normal-view {
  right: -260px;
}

.sidebar-list--fullscreen {
  right: -340px;
}

.sidebar-list--normal-view-shown {
  right: 80px;
}

.sidebar-list--fullscreen-shown {
  right: 0px;
}

.sidebar-list__main {
  flex: 1 1;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
}

.sidebar-list__main::-webkit-scrollbar {
  display: none;
}

.sidebar-list__main--padded {
  padding: 10px 20px;
}

.sidebar-list__footer {
  height: auto;
  min-height: 70px;
  background-color: #222427;
}

.sidebar-list__footer--max-height-big {
  max-height: 140px;
}

.sidebar-list__footer--max-height-small {
  max-height: 70px;
}

.l-layer-1 {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
}

.l-layer-2 {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
}

.l-center-xy {
  display: flex;
  align-items: center;
  justify-content: center;
}

.l-max-size {
  height: 100%;
  width: 100%;
}

.l-center-x {
  display: flex;
  justify-content: center;
}

.l-center-y {
  display: flex;
  align-items: center;
}

.l-tab-row {
  display: flex;
  flex-direction: row; /* default */
}

.l-tab-col {
  display: flex;
  flex-direction: column;
}

.l-tab-col--grow-y {
  height: 100%;
  min-height: 100%;
}

.l-tab__item-grow {
  flex: 1 0 auto;
}

.l-tab__item-static {
  flex: 0 1 auto;
}

.sidebar-list-header {
  min-height: 79px;
  max-height: 79px;
  background-color: #222427;
  border-bottom: solid 1px #222427;
}

.c-cross {
  width: auto;
  cursor: pointer;
  background-image: url(../../static/media/icon-close.476d188f.svg);
  height: 14px;
  width: 14px;
  opacity: 0.5;
  transition: opacity 0.2s;
  margin: 20px 0 0 20px;
}

.c-cross:hover {
  opacity: 1;
}

.sidebar-list-header__close-action {
  width: 34px;
  height: 34px;
  cursor: pointer;
}

.sidebar-list-header__close-action:before,
.sidebar-list-header__close-action:after {
  position: absolute;
  left: 26px;
  top: 18px;
  content: " ";
  height: 19px;
  width: 2px;
  background-color: #909193;
  border-radius: 5px;
}

.sidebar-list-header__close-action::before {
  -ms-transform: rotate(45deg);
      transform: rotate(45deg);
}
.sidebar-list-header__close-action::after {
  -ms-transform: rotate(-45deg);
      transform: rotate(-45deg);
}

.sidebar-list-header-messages {
  flex: 1 1 auto;
  margin-top: 18px;
  text-align: center;
}

.sidebar-list-header-messages__title {
  padding-right: 34px;
  text-align: center;
  text-transform: uppercase;
  color: #c2c4c7;
  margin-bottom: 6px;
}

.sidebar-list-header-messages__info {
  padding-right: 34px;
  text-align: center;
  color: #c2c4c7;
}

.chat-list-content {
  width: calc(100% + 17px);
  height: 100%;
  overflow-y: scroll;
  padding-right: 40px;
}

.chat-message-container {
  margin-top: 10px;
  display: flex;
}

.chat-message-container:not(:last-child) {
  margin-bottom: 14px;
}

.chat-message-spacer {
  flex: 1 1 auto;
}

.chat-message {
  position: relative;
  border-radius: 4px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
  padding: 10px;
  flex: 0 0 auto;
  max-width: 270px;
  min-width: 150px;
  white-space: pre-line;
}

.chat-message::after {
  content: "";
  position: absolute;
  width: 0;
  border: 10px solid transparent;
  display: block;
}

.chat-message--outgoing {
  background-color: #4f5660;
  margin-right: 18px;
}

.chat-message--outgoing::after {
  top: 10px;
  bottom: auto;
  left: auto;
  right: -7px;
  border-width: 5px 0 5px 7px;
  border-color: transparent #4f5660;
}

.chat-message--incoming {
  background-color: #3c3e41;
  margin-left: 10px;
}

.chat-message--incoming::after {
  top: 10px;
  bottom: auto;
  left: -7px;
  border-width: 5px 7px 5px 0;
  border-color: transparent #3c3e41;
}

.chat-message__from {
  font-size: 14px;
  font-weight: bold;
  color: #a8b78e;
  margin-bottom: 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chat-message__message {
  font-size: 14px;
  line-height: 22px;
  color: #e2e4e7;
  overflow-wrap: break-word;
}

.chat-message__time {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  text-align: right;
}

.chat-control {
  padding: 10px 20px;
  box-sizing: border-box;
  height: auto;
  min-height: 70px;
  max-height: 140px;
}
.chat-message-hint {
  height: 20px;
  width: 100%;
  margin-top: 5px;
  font-size: 13px;
  color: #c2c4c7;
  text-align: right;
}

.chat-message-input {
  height: auto;
  background-color: #3c3e41;
  border-radius: 4px;
  min-height: 45px;
}

.c-text-input {
  max-height: 120px;
  min-height: 50px;
}

.c-text-input__field {
  width: calc(100% - 24px);
  resize: none;
  background-color: #3c3e41;
  color: #e2e4e7;
  font-size: 14px;
  border: none;
  overflow-y: hidden !important;
  font-family: "Roboto", sans-serif;
  overflow-wrap: break-word;
  padding: 14px 0;
  margin: 0 14px;
  height: var(--chat-message-height);
}

.c-text-input__field--max-height-small {
  max-height: 68px;
}

.c-text-input__field--max-height-big {
  max-height: 90px;
}

::-webkit-scrollbar {
  width: 5px;
}

.chat-message-input__send-button {
  background: url(../../static/media/icon-send.87a26f76.svg);
  background-repeat: no-repeat;
  height: 18px;
  width: 18px;
  cursor: pointer;
  margin: 0 14px 14px 0;
}

.conference-control {
  position: absolute;
  height: 70px;
  width: 100%;
  bottom: 0;
  background-color: #222427;
}

.conference-control-action {
  position: relative;
  display: inline-block;
  height: 100%;
  bottom: 0;
}

.conference-control-action--half {
  width: 50%;
}

.conference-control-action--third {
  width: 33%;
}

.conference-control-action__mute-inline {
  position: absolute;
  cursor: pointer;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
  height: 24px;
  width: 24px;
  opacity: 0.5;
  transition: opacity 250ms ease-in;
}

.conference-control-action__mute-inline:hover {
  opacity: 1;
  transition: opacity 250ms ease-out;
}

.conference-control-action__lock-inline {
  position: absolute;
  cursor: pointer;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
  height: 24px;
  width: 24px;
  opacity: 0.5;
  transition: opacity 250ms ease-in;
}

.conference-control-action__lock-inline:hover {
  opacity: 1;
  transition: opacity 250ms ease-out;
}

.conference-control-action__recording-inline {
  position: absolute;
  cursor: pointer;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
  height: 24px;
  width: 24px;
  opacity: 0.5;
  transition: opacity 250ms ease-in;
}

.conference-control-action__recording-inline:hover {
  opacity: 1;
  transition: opacity 250ms ease-out;
}

.conference-control-action__mute-inline--on {
  fill: #e26b6b;
}

.conference-control-action__lock-inline--on {
  fill: #e26b6b;
}

.conference-control-action__recording-inline--on {
  fill: #e26b6b;
}

.conference-control-action__mute-inline--off {
  fill: #a9de45;
}

.conference-control-action__lock-inline--off {
  fill: #a9de45;
}

.conference-control-action__recording-inline--off {
  fill: #ffffff;
}

.btn {
  border-radius: 4px;
  text-align: center;
  min-width: 140px;
  padding: 10px;
  display: inline-block;
  cursor: pointer;
  box-shadow: none;
  transition: box-shadow 0.2s;
  border: 0px;
}

.btnlink {
  padding: 0px;
  margin: 0px;
}

.btn-grey {
  background-color: #9ba2ac;
  color: #ffffff;
}

.btn-cta {
  background-color: var(--actionbutton_background-color);
  color: var(--actionbutton_hover_background-color);
}

.btn:hover {
  box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.2);
}

.btn:focus,
.btn:active {
  box-shadow: inset 0 0 100px 100px rgba(0, 0, 0, 0.1);
}

label {
  text-align: left;
  display: block;
  margin-bottom: 5px;
}

.inputblock {
  display: inline-block;
  width: 100%;
}

.inputblock--big {
  margin-bottom: 14px;
}

.inputblock__child {
  text-align: left;
}

.inputblock__child:not(:last-child) {
  margin: 0 0 20px 0;
}

.input-field {
  border-radius: 4px;
  border: 1px solid #c2c4c7;
  font-size: 16px;
}

.input-field:checked + .slider {
  background-color: #a9de45;
}

.input-field:focus + .slider {
  box-shadow: 0 0 1px #a9de45;
}

.input-field:checked + .slider:before {
  -ms-transform: translateX(22px);
  transform: translateX(22px);
}

.input-field--text {
  width: calc(100% - 20.467px);
  display: block;
  padding: 10px 2%;
}

.input-field--text:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset;
}

.input-field--submit {
  width: 462px;
  display: none;
  padding: 10px 2%;
}

.input-field--checkbox {
  width: 12px;
  display: inline-block;
  padding: 0;
  margin: 0;
}

.input-field--number {
  width: 462px;
  display: inline-block;
  padding: 10px 2%;
  -moz-appearance: textfield;
}

.input-field--number::-webkit-inner-spin-button,
.input-field--number::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.textarea-input {
  width: 462px;
  padding: 10px 2%;
  border-radius: 4px;
  border: 1px solid #c2c4c7;
  font-size: 16px;
  overflow: auto;
  vertical-align: top;
  resize: none;
  font-family: inherit;
  cursor: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
}

::-webkit-input-placeholder {
  color: #999;
  font-weight: 100;
}

:-ms-input-placeholder {
  color: #999;
  font-weight: 100;
}

::placeholder {
  color: #999;
  font-weight: 100;
}

select {
  width: 100%;
  font-size: 14px;
  display: block;
}

/*
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
Toggle btn
https://www.w3schools.com/howto/tryit.asp?filename=tryhow_css_switch
*/

.switch {
  position: relative;
  width: 42px;
  height: 20px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #5d5f61;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

.left-right-container {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  width: 100%;
  margin-bottom: 14px;
  height: 38px;
}

.left {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 25%;
}
.right {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 75%;
}

.loginBox_c-pw-input__1boDu {
  height: 44px;
  width: 100%;
  box-sizing: border-box;
  border-top: 1px solid #c2c4c7;
  border-bottom: 1px solid #c2c4c7;
  border-left: 1px solid #c2c4c7;
  border-right: none;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  font-size: 16px;
  display: inline-block;
  padding: 10px;
  outline: none;
  -moz-appearance: textfield;
}

.loginBox_c-pw-input__1boDu::-webkit-inner-spin-button,
.loginBox_c-pw-input__1boDu::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.loginBox_c-pw-input__1boDu::-ms-reveal,
.loginBox_c-pw-input__1boDu::-ms-clear {
  display: none;
}

.loginBox_c-pw-vis__l10Ok {
  height: 44px;
  width: 44px;
  box-sizing: border-box;
  border-top: 1px solid #c2c4c7;
  border-bottom: 1px solid #c2c4c7;
  border-right: 1px solid #c2c4c7;
  border-left: none;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: #ffffff;
  outline: none;
}

.loginBox_c-pw-vis__icon__3lmau {
  height: 20px;
  width: 20px;
  cursor: pointer;
  outline: none;
}

.loginBox_c-pw-vis__icon--show__1mXFb {
  background-image: url(../../static/media/icon-show-password.b564bdbb.svg);
}

.loginBox_c-pw-vis__icon--hide__2TKv- {
  background-image: url(../../static/media/icon-hide-password.a7a6aef0.svg);
}

.star {
  padding: 5px;
  display: inline-block;
}

.star .star-bg {
  fill: #e2e4e7;
}

.star .star-border {
  fill: #cdced1;
}

.star.active .star-bg {
  fill: #fed600;
}

.star.active .star-border {
  fill: #e4c000;
}

.star:hover {
  cursor: pointer;
}

.c-spacer-ver {
  height: 5px;
}

.c-spacer-hor {
  width: 5px;
}

.c-btn {
  border-radius: 4px;
  text-align: center;
  padding: 10px;
  display: inline-block;
  cursor: pointer;
  box-shadow: none;
  transition: box-shadow 0.2s;
  border: 0px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.c-btn:hover {
  box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.2);
}

.c-btn:focus,
.c-btn:active {
  box-shadow: inset 0 0 100px 100px rgba(0, 0, 0, 0.1);
}

.c-btn--long {
  width: 180px;
}

.c-btn--small {
  width: 70px;
}

.c-btn--max {
  width: calc(100% - 20px);
}

.c-btn--grey {
  background-color: #9ba2ac;
  color: #ffffff;
}

.c-btn--branded {
  background-color: var(--actionbutton_background-color);
  color: var(--actionbutton_hover_background-color);
}

.c-darken {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}

.c-window-action-cross {
  width: auto;
  cursor: pointer;
  background-image: url(../../static/media/icon-close-lightbox.03946bcf.svg);
  height: 14px;
  width: 14px;
  opacity: 0.5;
  transition: opacity 0.2s;
  margin: 20px 20px 0 0;
}

.c-window-action-cross:hover {
  opacity: 1;
}

.c-welcome {
  min-width: 800px;
  max-width: 800px;
  background-color: #e2e4e7;
  border-radius: 4px;
  overflow: auto;
}

.c-welcome-tour {
  width: 100%;
  /* background-color: #e2e4e7; */
}

.c-tour-action {
  width: 330px;
}

.c-tour-action--start {
  height: 282px;
}

.c-tour-action--tour {
  height: 320px;
}

.c-tour-image {
  width: 380px;
}

.c-tour--padding-right {
  padding-right: 50px;
}

.c-tour--padding-left {
  padding-left: 50px;
}

.c-tour-action-title {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  color: #222427;
  font-size: 35px;
  padding-top: 35px;
  margin-bottom: 10px;
}

.c-tour-action-text {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  color: #222427;
  font-size: 16px;
  margin-bottom: 25px;
}

.c-tour-image__image-0 {
  width: 272px;
  height: 272px;
  background: url(../../static/media/tour-01-start.c6f5dcde.png);
  background-repeat: no-repeat;
  margin-left: 25px;
}

.c-tour-image__image-1 {
  width: 380px;
  height: 260px;
  background: url(../../static/media/tour-02-teilnehmer-einladen.a1ecef8a.png);
  background-repeat: no-repeat;
}

.c-tour-image__image-2 {
  width: 380px;
  height: 275px;
  background: url(../../static/media/tour-03-audiooption-waehlen.4505a330.png);
  background-repeat: no-repeat;
}

.c-tour-image__image-3 {
  width: 360px;
  height: 260px;
  background: url(../../static/media/tour-04-praesentatorrolle-uebergeben.97fcbe62.png);
  background-repeat: no-repeat;
}

.c-tour-image__image-4 {
  width: 360px;
  height: 260px;
  background: url(../../static/media/tour-05-stummschalten.e3c4c24d.png);
  background-repeat: no-repeat;
}

.c-tour-image__image-5 {
  width: 340px;
  height: 255px;
  background: url(../../static/media/tour-06-globale-funktionen.f61eabb8.png);
  background-repeat: no-repeat;
}

.c-tour-image__image-6 {
  width: 360px;
  height: 260px;
  background: url(../../static/media/tour-07-chat.8926e4e5.png);
  background-repeat: no-repeat;
}

.c-tour-image__image--left-margin {
  margin-left: 20px;
}

.c-btn-devider {
  width: 10px;
}

.c-btn-spacer {
  height: 28px;
}

.c-welcome-footer {
  width: 100%;
}

.c-welcome-footer--tour {
  height: 62px;
}

.c-progress {
  padding-bottom: 20px;
}

.c-progress-step {
  border-radius: 50%;
  height: 8px;
  width: 8px;
  background-color: #9ba3ac;
  margin: 0 7px;
}

.c-progress-step--active {
  opacity: 1;
}

.c-progress-step--inactive {
  opacity: 0.4;
}

.c-tour-check {
  padding-left: 50px;
  margin-bottom: 21px;
}

.c-tour-check__check-box {
  cursor: pointer;
}

.c-tour-check__text {
  padding-left: 5px;
  color: #222427;
  font-family: "Roboto", sans-serif;
}

.c-new {
  margin: 8px 50px 8px 50px;
  border: 1px solid #a9ccdf;
  border-radius: 4px;
  background-color: #deeff7;
  font-family: "Roboto", sans-serif;
  color: #222427;
  padding: 10px 16px;
  width: 700px;
}

.c-new__symbol {
  width: 40px;
  height: 22px;
  border-radius: 4px;
  background-color: #52addc;
  margin-right: 14px;
}

.c-symbol-text {
  font-weight: 500;
  color: #ffffff;
  font-size: 14px;
}

.c-new--empty {
  height: 60px;
}

.c-new__text {
  display: flex;
  align-items: flex-end;
}

.c-welcome-input-hidden {
  display: none;
}

.multiModal_c-multi-modal__2a7XD {
  min-width: 650px;
  max-width: 800px;
  background-color: #f2f2f2;
  border-radius: 4px;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.5);
}

.multiModal_c-multi-modal__header__3vjfo {
  margin-bottom: 30px;
}

.multiModal_c-title-text__3C5tR {
  margin: 40px 0 0 34px;
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  color: #222427;
  font-size: 20px;
  text-align: center;
}

.multiModal_c-multi-modal__categories__1MXFd {
  padding-bottom: 44px;
}

.multiModal_c-multi-modal__content__3tcG9 {
  box-shadow: inset 0 5px 5px -5px #666;
  padding: 25px 50px 45px 50px;
  background-color: #e2e4e7;
}

.c-image-button {
  width: 150px;
}

.c-image {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  border: 1px solid #9ba2ac;
  cursor: pointer;
}

.c-image:hover {
  background-color: #9ba2ac;
}

.c-image--clicked {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  border: 1px solid #9ba2ac;
  cursor: pointer;
  background-color: #9ba2ac;
}

.c-image:hover svg .st-conn-0 {
  fill: #f2f2f2;
}

.c-image--clicked svg .st-conn-0 {
  fill: #f2f2f2;
}

.c-name {
  margin-top: 16px;
  text-align: center;
  color: #222427;
}

.c-image__icon {
  margin: 23px;
  height: 34px;
  width: 34px;
}

.st-conn-0 {
  fill: #9ba2ac;
}
.st-conn-1 {
  display: none;
}

.select-content__label {
  text-align: left;
  color: #222427;
  margin-bottom: 5px;
}

.connectionDialin_c-info__3exGh {
  border: 1px solid #a9ccdf;
  border-radius: 4px;
  background-color: #deeff7;
  font-family: "Roboto", sans-serif;
  color: #222427;
  text-align: center;
  padding: 20px;
}

.connectionDialin_c-text__3R_Fj {
  color: #222427;
  width: calc(100% / 2);
}

.connectionDialin_c-text--right__3kN5r {
  padding-left: 5px;
  font-size: 20px;
}

.connectionDialin_c-text--left__22mHm {
  text-align: right;
  padding-right: 5px;
  line-height: 26px;
}

.connectionDialin_c-button__1zrTq {
  margin-top: 40px;
  width: 100%;
}

.connectionDialin_c-spacer__1h2b- {
  height: 25px;
}

.label {
  text-align: left;
  color: #222427;
  margin-bottom: 5px;
  font-family: "Roboto", sans-serif;
}

.prefix {
  border-top: 1px solid #c2c4c7;
  border-bottom: 1px solid #c2c4c7;
  border-left: 1px solid #c2c4c7;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  padding: 10px 0px 10px 2%;
  background-color: #ffffff;
  color: #222427;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
}

.input {
  border: 0;
  border-top: 1px solid #c2c4c7;
  border-bottom: 1px solid #c2c4c7;
  border-right: 1px solid #c2c4c7;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 10px 2% 10px 5px;
  width: 96%;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  -moz-appearance: textfield;
}

.input--border-left {
  border-left: 1px solid #c2c4c7;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.connectionDialout_c-button__XvEe6 {
  margin-top: 40px;
  width: 100%;
}

.connectionDialout_c-spacer__2qu6c {
  height: 25px;
}

/* https://codepen.io/ingomc/pen/ONrMqe */

.spinner {
  text-align: center;
}

.spinner__circle {
  margin: 1em;
  display: inline-block;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  border: 4px solid silver;
  animation: circ-anim 0.7s linear infinite;
}

.spinner__circle--grey {
  border-bottom-color: grey;
}

.spinner-text {
  font-family: "Roboto", sans-serif;
  color: #222427;
  text-align: center;
}

@keyframes circ-anim {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.c-warn {
  border: 1px solid #d8cd85;
  border-radius: 4px;
  background-color: #f8f3d6;
  font-family: "Roboto", sans-serif;
  color: #222427;
  text-align: center;
  padding: 20px;
  white-space: pre;
}

.invitationLink_c-label__2EJcW {
  text-align: left;
  color: #222427;
  margin-bottom: 5px;
  font-family: "Roboto", sans-serif;
}

.invitationLink_c-button__2XFhj {
  margin-top: 40px;
  margin-bottom: 25px;
  width: 100%;
}

.invitationMail_c-label__j8YfC {
  text-align: left;
  color: #222427;
  margin-bottom: 5px;
  font-family: "Roboto", sans-serif;
}

.invitationMail_c-textarea-input__gK2CJ {
  width: 96%;
  padding: 10px 2%;
  border-radius: 4px;
  border: 1px solid #c2c4c7;
  font-size: 16px;
  overflow: auto;
  vertical-align: top;
  resize: none;
  font-family: inherit;
  cursor: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.invitationMail_c-button__1L8rP {
  margin-top: 40px;
  width: 100%;
}

.dialoutEntry_c-dialout-entry__2grVA {
  margin-bottom: 10px;
}

.dialoutEntry_c-dropdown__3mKuU {
  padding: 10px;
  background-color: #ffffff;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  cursor: pointer;
  height: 24px;
}

.dialoutEntry_c-dropdown--normal-border__1-u0x {
  border: 1px solid #c2c4c7;
}

.dialoutEntry_c-dropdown--fail-border__2-324 {
  border: 1px solid #ffa6b1;
}

.dialoutEntry_c-dropdown__content__2U9CV {
  display: block;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 330px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  max-height: 200px;
  overflow: auto;
}

.dialoutEntry_c-country-number__1ZqB1 {
  color: #222427;
  margin: 0 10px;
}

.dialoutEntry_c-country-number--btn__19O4S {
  text-align: center;
  min-width: 53px;
  max-width: 53px;
}

.dialoutEntry_c-country-number--content__ri3hD {
  text-align: left;
  min-width: 250px;
  max-width: 250px;
}

.dialoutEntry_c-arrow-down__3zT5u {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #9ba2ac;
}

.dialoutEntry_c-input__3x3-q {
  padding: 10px;
  border-right: none;
  border-left: none;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  width: calc(100% - 21px);
  height: 24px;
  -moz-appearance: textfield;
}

.dialoutEntry_c-input--normal-border__12ly1 {
  border-top: 1px solid #c2c4c7;
  border-bottom: 1px solid #c2c4c7;
}

.dialoutEntry_c-input--fail-border__VGVWf {
  border-top: 1px solid #ffa6b1;
  border-bottom: 1px solid #ffa6b1;
}

.dialoutEntry_c-input__3x3-q::-webkit-inner-spin-button,
.dialoutEntry_c-input__3x3-q::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.dialoutEntry_c-dialout-status__9JjOX {
  border-top: 1px solid #c2c4c7;
  border-left: none;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: #ffffff;
  height: 44px;
  width: 44px;
  margin-left: -1px;
}

.dialoutEntry_c-dialout-status--normal-border__3oB1R {
  border-top: 1px solid #c2c4c7;
  border-bottom: 1px solid #c2c4c7;
  border-right: 1px solid #c2c4c7;
}

.dialoutEntry_c-dialout-status--fail-border__1ZiGR {
  border-top: 1px solid #ffa6b1;
  border-bottom: 1px solid #ffa6b1;
  border-right: 1px solid #ffa6b1;
}

.dialoutEntry_c-dialout-status__state__htrYt {
  height: 20px;
  width: 20px;
}

.dialoutEntry_c-dialout-status__state--success__2P9o_ {
  background: url(../../static/media/icon-dialout-success.05153885.svg);
}

.dialoutEntry_c-dialout-status__state--fail__27JaO {
  background: url(../../static/media/icon-dialout-error.10192e83.svg);
}

.dialoutEntry_c-dialout-btn__2rBdh {
  height: 34px;
  width: 34px;
  margin-left: 10px;
}

.dialoutEntry_c-dialout-btn--add__1-1k4 {
  background: url(../../static/media/icon-dialout-add-number.22c7f406.svg);
  cursor: pointer;
}

.dialoutEntry_c-dialout-btn--delete__v5LQz {
  background: url(../../static/media/icon-dialout-delete-number.2f21e5f3.svg);
  cursor: pointer;
}

/* https://codepen.io/ingomc/pen/ONrMqe */

.dialoutEntry_c-spinner__3F3Mj {
  text-align: center;
  margin-left: 5px;
}

.dialoutEntry_c-spinner__circle__wuZm2 {
  margin: 5px;
  display: inline-block;
  width: 21px;
  height: 21px;
  border-radius: 50%;
  border: 4px solid silver;
  animation: dialoutEntry_circ-anim__241sR 0.7s linear infinite;
}

.dialoutEntry_c-spinner__circle--grey__33Gh3 {
  border-bottom-color: grey;
}

@keyframes dialoutEntry_circ-anim__241sR {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.invitationDialout_c-label__3BYtI {
  text-align: left;
  color: #222427;
  margin-bottom: 5px;
  font-family: "Roboto", sans-serif;
}

.invitationDialout_c-button__2nCNH {
  margin-top: 40px;
  margin-bottom: 25px;
  width: 100%;
}

.invitationDialout_c-dialouts__38EwD {
  max-height: 190px;
  width: calc(100% + 17px);
  overflow-y: scroll;
  padding-right: 50px;
}

