.l-layer-1 {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
}

.l-layer-2 {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
}

.l-center-xy {
  display: flex;
  align-items: center;
  justify-content: center;
}

.l-max-size {
  height: 100%;
  width: 100%;
}

.l-center-x {
  display: flex;
  justify-content: center;
}

.l-center-y {
  display: flex;
  align-items: center;
}

.l-tab-row {
  display: flex;
  flex-direction: row; /* default */
}

.l-tab-col {
  display: flex;
  flex-direction: column;
}

.l-tab-col--grow-y {
  height: 100%;
  min-height: 100%;
}

.l-tab__item-grow {
  flex: 1 0 auto;
}

.l-tab__item-static {
  flex: 0 1 auto;
}
