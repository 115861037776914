@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700");

body {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
}

h1 {
  font-size: 50px;
  font-weight: 100;
}

h2 {
  font-size: 26px;
  font-weight: 100;
}

a {
  font-size: 16px;
  text-decoration: none;
  color: inherit;
}

a:visited {
  color: inherit;
}

p {
  font-size: 16px;
  line-height: 22px;
  margin-top: 0;
}

b {
  font-weight: 500;
}

/* ======================= */
/* = MEDIA QUERIES START = */
/* ======================= */

@media only screen and (max-width: 768px) {
  h1 {
    font-size: 30px;
    font-weight: 100;
  }

  h2 {
    font-size: 16px;
    font-weight: 100;
  }
}

/* ====================== */
/* = MEDIA QUERIES ENDE = */
/* ====================== */
