.control {
  position: absolute;
  width: 140px;
  height: 60px;
  left: 50%;
  transform: translate(-50%, 0);
}

.control--normalsize {
  bottom: 40px;
}

.control--fullscreen {
  bottom: 100px;
}

.control-action {
  position: relative;
  display: inline-block;
  cursor: pointer;
  width: 60px;
  height: 100%;
  border-radius: 50%;
}

.control-action--mute {
  background-color: #323437;
  opacity: 0.75;
  transition: opacity 250ms ease-in;
}

.control-action--mute:hover {
  opacity: 1;
  transition: opacity 250ms ease-out;
}

.control-action--hangup {
  background-color: #cd4236;
}

.control-action__placeholder {
  width: 20px;
  cursor: default;
}

.control-action__element {
  position: absolute;
  height: 34px;
  width: 34px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
}

.control-action__element--unmuted {
  -webkit-mask: url(../images/icon-micro-on.svg);
}

.control-action__element--muted {
  -webkit-mask: url(../images/icon-micro-off.svg);
}

.control-action__element--hangup {
  -webkit-mask: url(../images/icon-hang-up.svg);
}
