.c-label {
  text-align: left;
  color: #222427;
  margin-bottom: 5px;
  font-family: "Roboto", sans-serif;
}

.c-button {
  margin-top: 40px;
  margin-bottom: 25px;
  width: 100%;
}

.c-dialouts {
  max-height: 190px;
  width: calc(100% + 17px);
  overflow-y: scroll;
  padding-right: 50px;
}
