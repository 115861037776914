.main-block {
  flex: 1 1 auto;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.start-block {
  max-width: 500px;
  text-align: center;
  padding-bottom: 45px;
}

.start-block__left {
  display: inline-block;
  width: 40%;
  cursor: pointer;
  margin: 0 5%;
}

.start-block__right {
  display: inline-block;
  width: 40%;
  cursor: pointer;
  margin: 0 5%;
}

.start-block__label {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 24px;
  text-align: center;
  margin-top: 30px;
  color: var(--actionbutton_text-color);
}
.start-block__buyproduct {
  margin-top: 80px;
  padding: 20px 30px;
  border: 2px solid #666;
  border-radius: 5px;
  display: inline-block;
}

.start-block__buyproduct-link {
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  color: #aaa;
}

.start-block__buyproduct-link:visited {
  color: #aaa;
}

.starticon {
  width: 140px;
  height: 140px;
  margin: 0 auto;
}

svg circle {
  transition: fill 0.2s;
  /* transition: fill-opacity 0.5s; */
}

svg:hover circle.st0 {
  fill: var(--actionbutton_background-color-hover);
}

svg:focus circle,
svg:active circle {
  fill: var(--actionbutton_background-color-pushed);
}

svg .st0 {
  fill: var(--actionbutton_background-color);
}

svg .st1 {
  fill: var(--actionbutton_hover_background-color);
}

@media only screen and (max-width: 699px) {
  .start-block__label {
    font-size: 20px;
    margin-top: 30px;
  }
}

/* ======================= */
/* =    IE11 fallback    = */
/* ======================= */

.main-block-ie11 {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.start-block-ie11 {
  max-width: 500px;
  text-align: center;
}
