.c-welcome {
  min-width: 800px;
  max-width: 800px;
  background-color: #e2e4e7;
  border-radius: 4px;
  overflow: auto;
}

.c-welcome-tour {
  width: 100%;
  /* background-color: #e2e4e7; */
}

.c-tour-action {
  width: 330px;
}

.c-tour-action--start {
  height: 282px;
}

.c-tour-action--tour {
  height: 320px;
}

.c-tour-image {
  width: 380px;
}

.c-tour--padding-right {
  padding-right: 50px;
}

.c-tour--padding-left {
  padding-left: 50px;
}

.c-tour-action-title {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  color: #222427;
  font-size: 35px;
  padding-top: 35px;
  margin-bottom: 10px;
}

.c-tour-action-text {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  color: #222427;
  font-size: 16px;
  margin-bottom: 25px;
}

.c-tour-image__image-0 {
  width: 272px;
  height: 272px;
  background: url(../../images/tour-01-start.png);
  background-repeat: no-repeat;
  margin-left: 25px;
}

.c-tour-image__image-1 {
  width: 380px;
  height: 260px;
  background: url(../../images/tour-02-teilnehmer-einladen.png);
  background-repeat: no-repeat;
}

.c-tour-image__image-2 {
  width: 380px;
  height: 275px;
  background: url(../../images/tour-03-audiooption-waehlen.png);
  background-repeat: no-repeat;
}

.c-tour-image__image-3 {
  width: 360px;
  height: 260px;
  background: url(../../images/tour-04-praesentatorrolle-uebergeben.png);
  background-repeat: no-repeat;
}

.c-tour-image__image-4 {
  width: 360px;
  height: 260px;
  background: url(../../images/tour-05-stummschalten.png);
  background-repeat: no-repeat;
}

.c-tour-image__image-5 {
  width: 340px;
  height: 255px;
  background: url(../../images/tour-06-globale-funktionen.png);
  background-repeat: no-repeat;
}

.c-tour-image__image-6 {
  width: 360px;
  height: 260px;
  background: url(../../images/tour-07-chat.png);
  background-repeat: no-repeat;
}

.c-tour-image__image--left-margin {
  margin-left: 20px;
}

.c-btn-devider {
  width: 10px;
}

.c-btn-spacer {
  height: 28px;
}

.c-welcome-footer {
  width: 100%;
}

.c-welcome-footer--tour {
  height: 62px;
}

.c-progress {
  padding-bottom: 20px;
}

.c-progress-step {
  border-radius: 50%;
  height: 8px;
  width: 8px;
  background-color: #9ba3ac;
  margin: 0 7px;
}

.c-progress-step--active {
  opacity: 1;
}

.c-progress-step--inactive {
  opacity: 0.4;
}

.c-tour-check {
  padding-left: 50px;
  margin-bottom: 21px;
}

.c-tour-check__check-box {
  cursor: pointer;
}

.c-tour-check__text {
  padding-left: 5px;
  color: #222427;
  font-family: "Roboto", sans-serif;
}

.c-new {
  margin: 8px 50px 8px 50px;
  border: 1px solid #a9ccdf;
  border-radius: 4px;
  background-color: #deeff7;
  font-family: "Roboto", sans-serif;
  color: #222427;
  padding: 10px 16px;
  width: 700px;
}

.c-new__symbol {
  width: 40px;
  height: 22px;
  border-radius: 4px;
  background-color: #52addc;
  margin-right: 14px;
}

.c-symbol-text {
  font-weight: 500;
  color: #ffffff;
  font-size: 14px;
}

.c-new--empty {
  height: 60px;
}

.c-new__text {
  display: flex;
  align-items: flex-end;
}

.c-welcome-input-hidden {
  display: none;
}
