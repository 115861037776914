.c-label {
  text-align: left;
  color: #222427;
  margin-bottom: 5px;
  font-family: "Roboto", sans-serif;
}

.c-textarea-input {
  width: 96%;
  padding: 10px 2%;
  border-radius: 4px;
  border: 1px solid #c2c4c7;
  font-size: 16px;
  overflow: auto;
  vertical-align: top;
  resize: none;
  font-family: inherit;
  cursor: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.c-button {
  margin-top: 40px;
  width: 100%;
}
