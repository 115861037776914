.sidebar-list-header {
  min-height: 79px;
  max-height: 79px;
  background-color: #222427;
  border-bottom: solid 1px #222427;
}

.c-cross {
  width: auto;
  cursor: pointer;
  background-image: url(../../images/icon-close.svg);
  height: 14px;
  width: 14px;
  opacity: 0.5;
  transition: opacity 0.2s;
  margin: 20px 0 0 20px;
}

.c-cross:hover {
  opacity: 1;
}

.sidebar-list-header__close-action {
  width: 34px;
  height: 34px;
  cursor: pointer;
}

.sidebar-list-header__close-action:before,
.sidebar-list-header__close-action:after {
  position: absolute;
  left: 26px;
  top: 18px;
  content: " ";
  height: 19px;
  width: 2px;
  background-color: #909193;
  border-radius: 5px;
}

.sidebar-list-header__close-action::before {
  transform: rotate(45deg);
}
.sidebar-list-header__close-action::after {
  transform: rotate(-45deg);
}

.sidebar-list-header-messages {
  flex: 1 1 auto;
  margin-top: 18px;
  text-align: center;
}

.sidebar-list-header-messages__title {
  padding-right: 34px;
  text-align: center;
  text-transform: uppercase;
  color: #c2c4c7;
  margin-bottom: 6px;
}

.sidebar-list-header-messages__info {
  padding-right: 34px;
  text-align: center;
  color: #c2c4c7;
}
