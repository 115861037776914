.c-darken {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}

.c-window-action-cross {
  width: auto;
  cursor: pointer;
  background-image: url(../images/icon-close-lightbox.svg);
  height: 14px;
  width: 14px;
  opacity: 0.5;
  transition: opacity 0.2s;
  margin: 20px 20px 0 0;
}

.c-window-action-cross:hover {
  opacity: 1;
}
