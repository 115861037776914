/* =================== */
/* = SIDEBAR - START = */
/* =================== */

.sidebar {
  width: 80px;
  margin: 0;
  top: 0;
  display: block;
  position: fixed;
  right: 0;
  height: 100%;
  background-color: #323437;
  box-shadow: -3px 0px 10px 0 rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.sidebar ul {
  width: 80px;
  height: 100%;
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: absolute;
}

.sidebar ul li {
  width: 80px;
  background-color: none;
  transition: background-color 0.5s;
  /* transition: all ease-out 120ms; */
}

.sidebar ul li:hover,
.sidebar ul li.active,
.sidebar ul li.dropDownOpened {
  background-color: #3c3e41;
}

.sidebar ul li.userprofile:hover {
  background: #929497;
}

.sidebar-item__button{
  padding: 23px 0;
  margin: -23px 0;
  align-items: center;
  max-width: 80px;
}

.sidebar-item__button--fullscreen{
  padding: 23px 0;
  margin: -23px 0;
  align-items: center;
  max-width: 80px;
}

.sidebar li.sidebar-item {
  position: relative;
  cursor: pointer;
  padding: 23px 0;
}

.sidebar li span {
  margin: 0 auto;
  width: 34px;
  background-repeat: no-repeat;
  display: block;
}
.sidebar-item__children {
  display: block;
  height: 34px;
  width:34px;
  
  margin-left: auto;
  margin-right: auto;
}

.sidebar li span span {
  margin: 0;
  min-width: 220px;
  color: #c2c4c7;
  padding: 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.sidebar li span span a {
  color: #c2c4c7;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.sidebar li.userprofile {
  height: 60px;
  width: 60px;
  margin: 10px;
  padding: 0;
  top: 0;
  background-color: #929497;
  border-radius: 50px;
  cursor: default;
}

.sidebar h2 {
  color: #323437;
  margin: 0;
  padding: 14px 0 0;
}

.sidebar li.sidebar-item.fullscreen {
  position: absolute;
  /* bottom: 80px; */
  bottom: 160px;
  height: 34px;
}

.sidebar li.sidebar-item.settings {
  position: absolute;
  bottom: 80px;
  height: 34px;
}

.sidebar li.sidebar-item.quit {
  position: absolute;
  bottom: 0;
  height: 34px;
}

.sidebar li span.action-participantlist {
  background: url(../images/icon-participant-list.svg);
  height: 34px;
}

.sidebar li span.action-invite {
  background: url(../images/icon-add-participant.svg);
  height: 34px;
}

.sidebar li span.action-audio {
  background: url(../images/icon-phone.svg);
  height: 34px;
}

.sidebar li span.action-fullscreen {
  background: url(../images/icon-fullscreen.svg);
  height: 34px;
}

.sidebar li span.action-settings {
  background: url(../images/icon-settings.svg);
  height: 34px;
}

.sidebar li span.action-quit {
  background: url(../images/icon-logout.svg);
  height: 34px;
}

.sidebar li span.action-chat {
  background: url(../images/icon-chat.svg);
  height: 34px;
}

.sidebar-item-badge__border {
  position: absolute;
  background-color: transparent;
  border: 13px solid;
  border-right-color: transparent;
  border-top-color: transparent;
  border-bottom-color: #323437;
  border-left-color: #323437;
  border-radius: 50%;
  transition: border 0.5s;
}

.sidebar-item-badge__border--norml-view {
  top: 8px;
  right: 8px;
}

.sidebar-item-badge__border--fullscreen {
  top: -1px;
  right: -1px;
}

.sidebar-item-badge__content {
  position: absolute;
  background-color: #cd4236;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  width: 20px;
  height: 20px;
  line-height: 22px;
  border-radius: 50%;
  text-align: center;
}

.sidebar-item-badge__content--norml-view {
  top: 11px;
  right: 11px;
}

.sidebar-item-badge__content--fullscreen {
  top: 2px;
  right: 2px;
}

.sidebar ul li:hover .sidebar-item-badge__border {
  border-bottom-color: #3c3e41;
  border-left-color: #3c3e41;
}

.sidebar li span.action-usermenu .dropbtn {
  background: url(../images/icon-dots.svg);
  height: 34px;
  width: 34px;
}

/* ================== */
/* = SIDEBAR - ENDE = */
/* ================== */

/* =========================== */
/* = PARTICIPANTLIST - START = */
/* =========================== */

.participantlist {
  position: fixed;
  width: 340px;
  top: 0;
  right: -260px;
  height: 100%;
  background-color: #2d2f31;
  box-shadow: -3px 0px 10px 0 rgba(0, 0, 0, 0.3);
  transition: right var(--sidebar-content-transition-time);
  z-index: 1;
}

.participantlist.shown {
  right: 80px;
}
.participants {
  overflow: auto;
  height: 100%;
}

.participant-presenting {
  left: 10px;
  top: 21px;
  position: absolute;
}

.participant-presenting span.icon {
  position: absolute;
  mask: url(../images/icon-presenting.svg);
  -webkit-mask: url(../images/icon-presenting.svg);
  background-color: #c2c4c7;
  height: 16px;
  width: 16px;
}

.participant-presenting.active span.icon {
  background-color: #a9de45;
}

.participant-presenting .tooltiptext {
  display: inline-block;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 6px 10px;
  border-radius: 4px;
  right: 34px;
  margin-top: -8px;
  visibility: hidden;
  font-size: 14px;
  color: #fff;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.5s;
  transition-delay: 0.5s;
}

.participant-host {
  color: #8e9fb7;
  font-size: 14px;
}

.participant {
  border-bottom: solid 1px #222427;
  padding: 20px 20px 20px 40px;
  color: #c2c4c7;
  position: relative;
  background-color: none;
  transition: background-color 0.3s;
}

.participant:hover {
  background-color: #222427;
}

.participant-menu {
  float: right;
  top: 14px;
  position: absolute;
  right: 10px;
  padding: 0;
  margin: 0;
}

.participant-menu .dropbtn {
  background: url(../images/icon-dots.svg);
  height: 22px;
  width: 22px;
  top: -6px;
  right: 10px;
  position: absolute;
}

.participant-menu .dropbtn:hover {
  cursor: pointer;
  position: absolute;
}

.participant-name {
  padding-bottom: 3px;
  width: 240px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.participantlist-header {
  min-height: 85px;
  background-color: #222427;
  border-bottom: solid 1px #222427;
  padding-bottom: 20px;
}

.participantlist-close {
  background: url(../images/icon-close.svg);
  display: inline-block;
  height: 14px;
  width: 14px;
  margin: 20px 0 0 20px;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.2s;
}

.participantlist-close:hover {
  opacity: 1;
}

.participantlist-title {
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  margin: 0 0 6px 0;
  color: #c2c4c7;
  display: inline-block;
}

.participantlist-count {
  width: 100%;
  text-align: center;
  color: #c2c4c7;
}

/* ========================== */
/* = PARTICIPANTLIST - ENDE = */
/* ========================== */

.sidebar-entry-container {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  width: 100%;
}

.sidebar-entry-container--participant {
  height: 80px;
  color: #c2c4c7;
}

.sidebar-entry-container--header {
  height: 40px;
  background-color: #3c3e41;
  color: #7d7f81;
  user-select: none;
  border-bottom: solid 1px #2d2f31;
}

.sidebar-entry-container-divider {
  border-bottom: solid 1px #222427;
}

.sidebar-entry-content-divider {
  margin-top: 5px;
  margin-bottom: 5px;
}

.sidebar-entry-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 75%;
  padding: 10px 20px;
}

.sidebar-entry-content-title {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: baseline;
}

.sidebar-entry-content-title__text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sidebar-entry-content-title--host {
  color: #8e9fb7;
  font-size: 14px;
  bottom: 0px;
}

.sidebar-entry-content-symbols {
  width: 100%;
}

.sidebar-entry-content-symbols__placeholder {
  display: inline-block;
  height: 16px;
  width: 0px;
}

.sidebar-entry-content-symbols__presenting-inline {
  display: inline-block;
  fill: #c2c4c7;
  height: 16px;
  width: 16px;
  margin-right: 7px;
}

.sidebar-entry-content-symbols__presenting-inline--active {
  fill: #a9de45;
}

.sidebar-entry-content-symbols__on-hold-inline {
  display: inline-block;
  fill: #c2c4c7;
  height: 16px;
  width: 16px;
  margin-right: 7px;
}

.sidebar-entry-content-symbols__mute-inline {
  display: inline-block;
  fill: #e26b6b;
  height: 16px;
  width: 16px;
  margin-right: 7px;
}

.sidebar-entry-content-symbols__active-speaker-inline {
  display: inline-block;
  fill: #a9de45;
  height: 16px;
  width: 16px;
  margin-right: 7px;
}

.sidebar-entry-symbols {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
}

.sidebar-entry-symbols__dropbtn {
  height: 22px;
  width: 22px;
  /* opacity: 0;
  transition: opacity 250ms ease-in; */
}

/* .sidebar-entry-container--participant:hover .sidebar-entry-symbols__dropbtn {
  opacity: 1;
  transition: opacity 250ms ease-out;
} */

.sidebar-entry-symbols__arrow {
  width: 7px;
  height: 7px;
  border-radius: 1px;
  border-color: #7d7f81;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-right-style: solid;
  border-right-width: 2px;
}

.sidebar-entry-symbols__arrow--up {
  transform: rotate(225deg);
}

.sidebar-entry-symbols__arrow--down {
  transform: rotate(45deg);
}

.sidebar-entry-symbols__arrow--closing {
  transform: rotate(-315deg);
  transition: transform 500ms ease;
}

.sidebar-entry-symbols__arrow--opening {
  transform: rotate(-135deg);
  transition: transform 500ms ease;
}

.user-menu__symbol {
  background: url(../images/icon-dots.svg);
  height: 34px;
  width: 34px;
}

.hidden {
  display: none;
}

.sidebar-settings-list {
  overflow: hidden;
  height: 100%;
  padding: 10px 20px;
  color: #c2c4c7;
}
