.c-multi-modal {
  min-width: 650px;
  max-width: 800px;
  background-color: #f2f2f2;
  border-radius: 4px;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.5);
}

.c-multi-modal__header {
  margin-bottom: 30px;
}

.c-title-text {
  margin: 40px 0 0 34px;
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  color: #222427;
  font-size: 20px;
  text-align: center;
}

.c-multi-modal__categories {
  padding-bottom: 44px;
}

.c-multi-modal__content {
  box-shadow: inset 0 5px 5px -5px #666;
  padding: 25px 50px 45px 50px;
  background-color: #e2e4e7;
}
