.c-info {
  border: 1px solid #a9ccdf;
  border-radius: 4px;
  background-color: #deeff7;
  font-family: "Roboto", sans-serif;
  color: #222427;
  text-align: center;
  padding: 20px;
}

.c-text {
  color: #222427;
  width: calc(100% / 2);
}

.c-text--right {
  padding-left: 5px;
  font-size: 20px;
}

.c-text--left {
  text-align: right;
  padding-right: 5px;
  line-height: 26px;
}

.c-button {
  margin-top: 40px;
  width: 100%;
}

.c-spacer {
  height: 25px;
}
