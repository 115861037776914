.conference-control {
  position: absolute;
  height: 70px;
  width: 100%;
  bottom: 0;
  background-color: #222427;
}

.conference-control-action {
  position: relative;
  display: inline-block;
  height: 100%;
  bottom: 0;
}

.conference-control-action--half {
  width: 50%;
}

.conference-control-action--third {
  width: 33%;
}

.conference-control-action__mute-inline {
  position: absolute;
  cursor: pointer;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 24px;
  width: 24px;
  opacity: 0.5;
  transition: opacity 250ms ease-in;
}

.conference-control-action__mute-inline:hover {
  opacity: 1;
  transition: opacity 250ms ease-out;
}

.conference-control-action__lock-inline {
  position: absolute;
  cursor: pointer;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 24px;
  width: 24px;
  opacity: 0.5;
  transition: opacity 250ms ease-in;
}

.conference-control-action__lock-inline:hover {
  opacity: 1;
  transition: opacity 250ms ease-out;
}

.conference-control-action__recording-inline {
  position: absolute;
  cursor: pointer;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 24px;
  width: 24px;
  opacity: 0.5;
  transition: opacity 250ms ease-in;
}

.conference-control-action__recording-inline:hover {
  opacity: 1;
  transition: opacity 250ms ease-out;
}

.conference-control-action__mute-inline--on {
  fill: #e26b6b;
}

.conference-control-action__lock-inline--on {
  fill: #e26b6b;
}

.conference-control-action__recording-inline--on {
  fill: #e26b6b;
}

.conference-control-action__mute-inline--off {
  fill: #a9de45;
}

.conference-control-action__lock-inline--off {
  fill: #a9de45;
}

.conference-control-action__recording-inline--off {
  fill: #ffffff;
}
