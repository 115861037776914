.footer-block {
  min-height: 50px;
  margin: auto;
}

.footer-info {
  text-align: center;
}

.footer-info__link,
.footer-info__link:visited {
  margin: 0 20px;
  color: #c2c4c7;
  transition: color 0.5s;
}

.footer-info__link:hover,
.footer-info__link:active {
  color: #ffffff;
}

@media only screen and (max-width: 699px) {
  .footer-block {
    min-height: 220px;
  }
  .footer-info__link {
    margin: 20px 0;
    display: block;
  }
}

/* ======================= */
/* =    IE11 fallback    = */
/* ======================= */

.footer-block-ie11 {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 50px;
  margin: auto;
  padding: 0px 50px;
  z-index: 1;
}

.footer-info__link-ie11,
.footer-info__link-ie11:visited {
  margin: 0 20px;
  color: #c2c4c7;
  transition: color 0.5s;
}

.footer-info__link-ie11:hover,
.footer-info__link-ie11:active {
  color: #ffffff;
}

@media only screen and (max-width: 699px) {
  .footer-ie11 {
    height: 220px;
  }
  .footer-info__link-ie11 {
    margin: 20px 0;
    display: block;
  }
}
