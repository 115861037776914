.c-help {
  position: absolute;
  left: 40px;
  bottom: 40px;
  height: 60px;
  width: 60px;
  background-color: #323437;
  opacity: 0.75;
  border-radius: 50%;
  cursor: pointer;
}

.c-help:hover {
  opacity: 1;
}

.c-help-symbol {
  height: 28px;
  width: 28px;
  margin: 16px;
  background-color: #ffffff;
  background: url(../../images/icon-help.svg);
}
