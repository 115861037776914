/* https://codepen.io/ingomc/pen/ONrMqe */

.spinner {
  text-align: center;
}

.spinner__circle {
  margin: 1em;
  display: inline-block;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  border: 4px solid silver;
  animation: circ-anim 0.7s linear infinite;
}

.spinner__circle--grey {
  border-bottom-color: grey;
}

.spinner-text {
  font-family: "Roboto", sans-serif;
  color: #222427;
  text-align: center;
}

@keyframes circ-anim {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
