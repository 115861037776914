/* ================ */
/* = BODY - START = */
/* ================ */

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  color: #ffffff;
  background-color: #222427;
}

:focus {
  outline: none;
}

/* =============== */
/* = BODY - ENDE = */
/* =============== */

/* =================== */
/* = TOOLTIP - START = */
/* =================== */

.tooltip .tooltiptext {
  display: inline-block;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 4px;
  visibility: hidden;
  font-size: 14px;
  color: #fff;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.5s;
  transition-delay: 0.5s;
}

.tooltip__text--sidebar-button-adjustment {
  right: 95px;
  margin-top: 3px;
  padding: 6px 10px;
}

.tooltip__text--participant-list-settings-adjustment {
  right: 30px;
  margin-top: -6px;
  padding: 6px 10px;
}

.tooltip__text--conf-control-adjustment {
  right: 80px;
  margin-top: 18px;
  padding: 6px 10px;
}

.tooltip__text--help-button-adjustment {
  left: 70px;
  bottom: 13px;
  padding: 6px 10px;
}

/* tooltip arrow */
.tooltip .tooltiptext--right:before {
  content: "";
  display: block;
  position: absolute;
  right: -4px;
  top: 10px;
  transform: rotate(45deg);
  width: 10px;
  height: 10px;
  background-color: inherit;
}

/* tooltip arrow */
.tooltip .tooltiptext--left:before {
  content: "";
  display: block;
  position: absolute;
  left: -4px;
  top: 10px;
  transform: rotate(45deg);
  width: 10px;
  height: 10px;
  background-color: inherit;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

/* ================== */
/* = TOOLTIP - ENDE = */
/* ================== */

.centerer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.creds-input {
  margin-bottom: 14px;
  display: inline-block;
  width: 100%;
}

.creds-input__pin-label {
  text-align: left;
  display: inline-block;
  margin-bottom: 5px;
  margin-right: 6px;
}

.creds-input__info-icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-image: url("../images/icon-tooltip.svg");
  width: 20px;
  height: 20px;
  position: relative;
  top: 3px;
  cursor: pointer;
  margin-right: 6px;
}

.creds-input__tooltip {
  background-color: rgba(0, 0, 0, 0.7);
  min-width: 170px;
  border-radius: 4px;
  padding: 6px 10px;
  margin-left: 25px;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s;
  transition-delay: 0.5s;
  position: absolute;
  cursor: default;
  /* white-space: pre-line; */
}

.creds-input__info-icon:hover .creds-input__tooltip {
  visibility: visible;
  opacity: 1;
}

.electron-select {
  width: 620px;
  margin: 0 auto;
  padding: 0;
  background-color: #f2f2f2;
  border-radius: 4px;
  overflow: auto;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.5);
  position: relative;
}

.electron-thumbnails {
  display: flex;
  max-height: 360px;
  margin: 24px 24px 0px 24px;
  flex-wrap: wrap;
  /* justify-content: space-around; */
  overflow-y: auto;
}

.electron-thumbnail {
  position: relative;
  margin-bottom: 14px;
  margin-left: 9px;
  width: 160px;
  height: 165px;
  padding: 8px;
}

.electron-thumbnail:hover::after {
  content: "\A";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  border: 2px solid #abdc4f;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  /* border: 4px solid #abdc4f;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; */

  /* top: -9px;
  left: -8px;
  width: 110%;
  height: 110%;
  border-radius: 4px;
  box-shadow: 1px 4px 10px #222427;
  opacity: 0.5;
  background: #abdc4f; */
}

.electron-thumbnail--border {
  content: "\A";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  border: 2px solid #abdc4f;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 6px;
}

.electron-thumbnail__image {
  height: 142px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.electron-thumbnail__title {
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  margin-top: 4px;
}

.tooltip__text--dialout-menu-entry {
  right: 95px;
  margin-top: 3px;
  padding: 6px 10px;
}
