.c-dialout-entry {
  margin-bottom: 10px;
}

.c-dropdown {
  padding: 10px;
  background-color: #ffffff;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  cursor: pointer;
  height: 24px;
}

.c-dropdown--normal-border {
  border: 1px solid #c2c4c7;
}

.c-dropdown--fail-border {
  border: 1px solid #ffa6b1;
}

.c-dropdown__content {
  display: block;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 330px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  max-height: 200px;
  overflow: auto;
}

.c-country-number {
  color: #222427;
  margin: 0 10px;
}

.c-country-number--btn {
  text-align: center;
  min-width: 53px;
  max-width: 53px;
}

.c-country-number--content {
  text-align: left;
  min-width: 250px;
  max-width: 250px;
}

.c-arrow-down {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #9ba2ac;
}

.c-input {
  padding: 10px;
  border-right: none;
  border-left: none;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  width: calc(100% - 21px);
  height: 24px;
  -moz-appearance: textfield;
}

.c-input--normal-border {
  border-top: 1px solid #c2c4c7;
  border-bottom: 1px solid #c2c4c7;
}

.c-input--fail-border {
  border-top: 1px solid #ffa6b1;
  border-bottom: 1px solid #ffa6b1;
}

.c-input::-webkit-inner-spin-button,
.c-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.c-dialout-status {
  border-top: 1px solid #c2c4c7;
  border-left: none;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: #ffffff;
  height: 44px;
  width: 44px;
  margin-left: -1px;
}

.c-dialout-status--normal-border {
  border-top: 1px solid #c2c4c7;
  border-bottom: 1px solid #c2c4c7;
  border-right: 1px solid #c2c4c7;
}

.c-dialout-status--fail-border {
  border-top: 1px solid #ffa6b1;
  border-bottom: 1px solid #ffa6b1;
  border-right: 1px solid #ffa6b1;
}

.c-dialout-status__state {
  height: 20px;
  width: 20px;
}

.c-dialout-status__state--success {
  background: url(../../../images/icon-dialout-success.svg);
}

.c-dialout-status__state--fail {
  background: url(../../../images/icon-dialout-error.svg);
}

.c-dialout-btn {
  height: 34px;
  width: 34px;
  margin-left: 10px;
}

.c-dialout-btn--add {
  background: url(../../../images/icon-dialout-add-number.svg);
  cursor: pointer;
}

.c-dialout-btn--delete {
  background: url(../../../images/icon-dialout-delete-number.svg);
  cursor: pointer;
}

/* https://codepen.io/ingomc/pen/ONrMqe */

.c-spinner {
  text-align: center;
  margin-left: 5px;
}

.c-spinner__circle {
  margin: 5px;
  display: inline-block;
  width: 21px;
  height: 21px;
  border-radius: 50%;
  border: 4px solid silver;
  animation: circ-anim 0.7s linear infinite;
}

.c-spinner__circle--grey {
  border-bottom-color: grey;
}

@keyframes circ-anim {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
