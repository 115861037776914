.c-warn {
  border: 1px solid #d8cd85;
  border-radius: 4px;
  background-color: #f8f3d6;
  font-family: "Roboto", sans-serif;
  color: #222427;
  text-align: center;
  padding: 20px;
  white-space: pre;
}
