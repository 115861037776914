.label {
  text-align: left;
  color: #222427;
  margin-bottom: 5px;
  font-family: "Roboto", sans-serif;
}

.prefix {
  border-top: 1px solid #c2c4c7;
  border-bottom: 1px solid #c2c4c7;
  border-left: 1px solid #c2c4c7;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  padding: 10px 0px 10px 2%;
  background-color: #ffffff;
  color: #222427;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
}

.input {
  border: 0;
  border-top: 1px solid #c2c4c7;
  border-bottom: 1px solid #c2c4c7;
  border-right: 1px solid #c2c4c7;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 10px 2% 10px 5px;
  width: 96%;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  -moz-appearance: textfield;
}

.input--border-left {
  border-left: 1px solid #c2c4c7;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
