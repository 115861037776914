.main {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.main--ie11-fix {
  height: 0px;
}

.main-background {
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    var(--login_background);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.main-background-blured {
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    var(--login_background);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  filter: blur(var(--blur-value));
}

.bg-blur
  div:not(.modal-container)
  div:not(.header-block)
  div:not(.footer-block) {
  filter: blur(var(--blur-value));
}

.hiddeniframe {
  display: none;
}

/* ======================= */
/* =    IE11 fallback    = */
/* ======================= */

.App {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.App:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--login_background_image_shade);
}

.App-background {
  background: var(--login_background);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.App-background-blured {
  background: var(--login_background);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  filter: blur(var(--blur-value));
}
